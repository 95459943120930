<template>
  <div class="checkout-place-btn">
    <s-popover
      class="checkout-activity-popover__wrap not-fsp-element"
      theme="dark"
      placemen="top-end"
      :append-to-body="false"
      :show-close-icon="true"
      :model-value="showActivityPopover"
      trigger="unset"
      :prop-style="{ 
        minWidth: '132px', 
        maxWidth: '260px', 
        background: `rgba(0, 0, 0, 0.8)`,
        zIndex: 199,
        transform: 'translateZ(199px)'
      }"
      :outside-close="false"
      @opened="openActivityPopover"
      @before-close="closeActivityPopover"
    >
      <ActivityPopover 
        class="not-fsp-element"
        :checkout="checkout"
        :language="language"
        :activity-popover-type="activityPopoverType"
        :integrity-promotion-info="integrityPromotionInfo"
        :promotion-list="promotionList"
        :under-price-abt-value="underPriceAbtValue"
        :flash-sale-group-list="flashSaleGroupList"
        :earliest-expiry-coupon="earliestExpiryCoupon"
      />

      <template #reference>
        <CheckoutSummaryButton 
          :paypal-btn-show="paypalBtnShow"
          :paypal-venmo-btn-show="paypalVenmoBtnShow"
          :installment-info="installmentInfo"
          :paypal-ga-vault="paypalGaVault"
          @create-order="createOrder"
        />
      </template>
    </s-popover>

    <p
      v-if="status.cartStock"
      class="sold-out-tip"
      style="word-break: break-word"
      v-html="language.SHEIN_KEY_PC_14712"
    ></p>
    <!-- 同意 tc&pp 协议 -->
    <AgreePolicyTip :language="language" />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter/index'

import AgreePolicyTip from '../../vue_tpls/AgreePolicyTip.vue'
import ActivityPopover from 'public/src/pages/checkout/vue_tpls/activity_popover/Index.vue'
import CheckoutSummaryButton from './CheckoutSummaryButton'


daEventCenter.addSubscriber({ modulecode: '1-11-1' })

export default {
  components: { 
    AgreePolicyTip, 
    ActivityPopover,
    CheckoutSummaryButton,
  },
  props: {
    paypalBtnShow: {
      type: Boolean,
      default: () => {
        return false
      },
    },
    paypalVenmoBtnShow: {
      type: Boolean,
      default: () => {
        return false
      },
    },
    installmentInfo: {
      type: Object,
      default: function () {
        return {}
      },
    },
    paypalGaVault: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isCloseActivityPopover: false,
      activityPopoverCache: [],
      alreadyExposeScene: false,
      isClient: false,
    }
  },
  computed: {
    ...mapState([
      'status',
      'checkout',
      'integrityPromotionInfo',
      // 'isInitPromotion',
      'promotionList',
      'language',
    ]),
    ...mapGetters([
      'showOrderRulesByAbt',
      'showOrderBubbleSort',
      'underPriceAbtValue',
      'flashSaleGroupList',
      'earliestExpiryCoupon'
    ]),
    activityPopoverInfo() {
      const { promotion_discount_info = [], carts = [] } = this.checkout.results?.carts?.data || {}

      return {
        promotion: !!promotion_discount_info.find(promotion => +promotion.discount_price?.amount > 0),
        inventory: !!(carts.some(product => Number(product?.stock_show_type) === 0)), // stock_show_type等于0表示低库存
        coupon: !!this.earliestExpiryCoupon
      }
    },
    activityPopoverType() {
      let popoverList = []
      Object.entries(this.activityPopoverInfo).forEach(([key, val]) => {
        if(val) popoverList.push(key)
      })

      // 当同时满足多个利益点，则按 abt 优先级进行展示
      const type = this.showOrderBubbleSort.find(value => (
        popoverList.includes(value)
      ))

      // if(popoverList.includes('promotion')) {
      //   type = 'promotion'
      // } else if(popoverList.includes('lowStock')) {
      //   type = 'lowStock'
      // } else if(popoverList.includes('coupon')) {
      //   type = 'coupon'
      // }

      return type
    },
    activityPopoverList() {
      if(!this.showOrderRulesByAbt) return []

      let popoverList = []
      Object.entries(this.activityPopoverInfo).forEach(([key, val]) => {
        if(val) popoverList.push(key)
      })

      return popoverList
    },
    showActivityPopover() {
      if (!this.isClient) {
        return false
      }
      return !!this.activityPopoverList.length && 
        !this.isCloseActivityPopover && 
        !!this.activityPopoverType
    },
  },
  watch: {
    activityPopoverList: {
      handler(v) {
        if(!!v?.length) {
          this.getActivityPopoverCache()
          this.handleCloseActivityPopover()
        }
      },
      deep: true
    },
    activityPopoverInfo: {
      handler() {
        this.exposeScene()
      },
      deep: true,
      immediate: true
    },
  },
  mounted() {
    this.isClient = true
    this.getActivityPopoverCache()
    if(!!this.activityPopoverCache.length) this.isCloseActivityPopover = true
  },
  methods: {
    // 注意！！！此处不要有任何return事件，统一收口至最尾节点处理！！！下单按钮点击事件需要上报埋点及监控！！！
    createOrder() {
      this.$emit('createOrder')
    },
    setActivityPopoverCache(value) {
      let popoverList = typeof sessionStorage.getItem('checkout_activity_popover') === 'string' ? JSON.parse(sessionStorage.getItem('checkout_activity_popover')) : []
      if(!!value && !popoverList.includes(value)) {
        popoverList.push(value)
        sessionStorage.setItem('checkout_activity_popover', JSON.stringify(popoverList))
        this.activityPopoverCache = popoverList
      }
    },
    getActivityPopoverCache() {
      if(typeof window == 'undefined') return

      let popoverList = typeof sessionStorage.getItem('checkout_activity_popover') === 'string' ? JSON.parse(sessionStorage.getItem('checkout_activity_popover')) : []
      this.activityPopoverCache = popoverList
    },
    handleCloseActivityPopover() {
      if(!!this.activityPopoverCache.length && !this.activityPopoverCache.includes(this.activityPopoverType)) this.isCloseActivityPopover = true
    },
    openActivityPopover() {
      daEventCenter.triggerNotice({
        daId: '1-11-1-179',
        extraData: {
          benefits_type: this.activityPopoverType == 'promotion' ? 'huodong' : (this.activityPopoverType == 'inventory' ? 'kucun' : 'quan'),
        }
      })

      let popoverList = typeof sessionStorage.getItem('checkout_activity_popover') === 'string' ? JSON.parse(sessionStorage.getItem('checkout_activity_popover')) : []
      if(!!this.activityPopoverType && !popoverList.includes(this.activityPopoverType)) {
        this.setActivityPopoverCache(this.activityPopoverType)
      }

      const { CHECKOUT_ACTIVITY_POPOVER_TIME = 8 } = this.checkout || {}
      setTimeout(() => {
        this.isCloseActivityPopover = true
      }, +CHECKOUT_ACTIVITY_POPOVER_TIME * 1000)
    },
    closeActivityPopover() {
      this.isCloseActivityPopover = true

      daEventCenter.triggerNotice({
        daId: '1-11-1-180',
      })
    },
    exposeScene() {
      if(typeof window == 'undefined') return
      if(this.alreadyExposeScene) return

      if(!!this.activityPopoverType) {
        daEventCenter.triggerNotice({
          daId: '1-11-1-169',
          extraData: {
            scenes: 'placeorderBenefits',
            benefits_type: this.activityPopoverType == 'promotion' ? 'huodong' : (this.activityPopoverType == 'inventory' ? 'kucun' : 'quan'),
          }
        })
        this.alreadyExposeScene = true
      }
    },
  },
  emits: ['createOrder'],
}
</script>

<style lang="less" scoped>
.checkout-place-btn {
  padding: 0 16px 16px;
  background: #fff;
}
</style>

<style lang="less">
.checkout-activity-popover__wrap {
  display: block;
  width: 100%;
  .sui-popover__content {
    padding: 12px;
    right: 0;
    bottom: 67px;
    /* stylelint-disable-next-line declaration-no-important */
    left: auto !important;
    /* stylelint-disable-next-line declaration-no-important */
    top: auto !important;

    .sui-popover__content-arrow {
      /* stylelint-disable-next-line declaration-no-important */
      left: auto !important;
      /* stylelint-disable-next-line declaration-no-important */
      top: auto !important;
      right: 8px;
      bottom: -10px
    }
    .sui-popover__close-arrow {
      right: 1px;
      top: 1px;
    }
  }
}
.c-order-summary{
	.sold-out-tip{
		margin-top: 10px;
		border: 1px solid #d53333;
    padding: 4px 10px;
    color: red;
    background-color: fade(#d53333,10%);
    line-height: 18px;
    strong a {
      text-transform: capitalize;
      color: red;
      text-decoration: underline;
    }
  }
}
</style>

