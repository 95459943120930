<template>
  <div class="checkout-activity-popover__wrap">
    <component
      :is="componentInfo.name"
      :key="componentInfo.name"
      class="checkout-activity-popover__item"
      v-bind="componentInfo.props"
    />
  </div>
</template>

<script>
import Promotion from './Promotion.vue'
import Lowstock from './Lowstock.vue'
import Coupon from './Coupon.vue'

export default {
  name: 'ActivityPopover',
  components: {
    Promotion,
    Lowstock,
    Coupon,
  },
  props: {
    checkout: {
      type: Object,
      default: () => {}
    },
    language: {
      type: Object,
      default: () => {}
    },
    activityPopoverType: {
      type: String,
      default: ''
    },
    integrityPromotionInfo: {
      type: Object,
      default: () => {}
    },
    promotionList: {
      type: Array,
      default: () => []
    },
    underPriceAbtValue: {
      type: Boolean,
      default: false
    },
    flashSaleGroupList: {
      type: Object,
      default: () => {}
    },
    earliestExpiryCoupon: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    componentList() {
      return [
        {
          name: 'Promotion',
          type: 'promotion',
          props: {
            checkout: this.checkout,
            language: this.language,
            integrityPromotionInfo: this.integrityPromotionInfo,
            promotionList: this.promotionList,
            underPriceAbtValue: this.underPriceAbtValue,
            flashSaleGroupList: this.flashSaleGroupList,
          },
        },
        {
          name: 'Lowstock',
          type: 'inventory',
          props: {
            checkout: this.checkout,
            language: this.language,
            underPriceAbtValue: this.underPriceAbtValue,
          },
        },
        {
          name: 'Coupon',
          type: 'coupon',
          props: {
            checkout: this.checkout,
            language: this.language,
            coupon: this.earliestExpiryCoupon,
          },
        },
      ]
    },
    componentInfo() {
      return this.componentList.find(item => item.type == this.activityPopoverType) || {}
    },
  },
}
</script>

<style lang="less" scoped>
.checkout-activity-popover__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
