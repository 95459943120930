<template>
  <li class="activity-popover__goods-item">
    <div class="goods-item__img">
      <img
        class="i-img"
        :src="transformImg({ img: item.product.goods_img })"
      />
      <div
        v-if="item.product.unit_discount > 0"
        class="item-discount"
        :class="{
          'activity-flash': promotionContent && [1, 4, 5].includes(item.flashType),
          'new-person-flash':
            promotionContent && [2, 3].includes(item.flashType),
          'premium-member-discount': isPremiumMember,
          'promotion-discount': promotionDiscount
        }"
        aria-hidden="true"
      >
        <span>-{{ item.product.unit_discount }}%</span>
      </div>

      <div
        v-if="outQuantity > 0"
        class="goods-item__mask"
      >
        <div class="goods-item__mask-detail">
          +{{ outQuantity }}
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import { transformImg } from '@shein/common-function'

export default {
  name: 'GoodsItem',
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    language: {
      type: Object,
      default: () => {}
    },
    outQuantity: {
      type: Number,
      default: 0
    },
    underPriceAbtValue: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    promotionContent() {
      return this.item?.promotionTypeIds?.includes(10)
    },
    isPremiumMember() {
      return this.item?.promotionTypeIds?.includes(29) ||
        this.item?.discount_product_mark
        ? true
        : false
    },
    promotionDiscount(){
      const { product = {}, promotionTypeIds = [] } = this.item
      return (this.underPriceAbtValue && promotionTypeIds.includes(30)) && product.unit_discount
    },
  },
  methods: {
    transformImg,
  }
}
</script>

<style lang="less" scoped>
.activity-popover__goods-item {
  .margin-r(4px);
  &:last-child {
    .margin-r(0);
  }
  .goods-item__img {
    position: relative;
    width: 38px;
    height: 38px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .item-discount{
    .mixin-discount-tag__min();
    top: 0;
    .left(0);
    font-size: 9px;
    width: auto;
    height: auto;
    padding: 1px 2px;
    line-height: 1.35;

    &.activity-flash {
      color: #222;
      & when(@IS_RW) {
        color: @sui_color_white;
      }
      background: @sui_color_flash;
    }
    &.premium-member-discount {
      background: #FFE2D3;
      color: #873C00;
    }
    &.new-person-flash{
      background: @sui_color_discount;
      color: @sui_color_white;
    }
    &.promotion-discount{
      background: @sui_color_promo;
      color: @sui_color_white;
    }
  }
  .goods-item__mask {
    position: absolute;
    background: rgba(0, 0, 0, 0.70);
    top: 0;
    .left(0);
    width: 100%;
    height: 100%;
    .goods-item__mask-detail {
      position: absolute;
      font-size: 12px;
      color: #fff;
      font-weight: 600;
      text-align: center;
      top: 50%;
      .left(50%);
      transform: translate(-50%, -50%);
    }
  }
}
</style>
