<template>
  <div class="coupon-popover">
    <div 
      class="coupon-popover__coupon j-activity-left"
      :class="{
        'coupon-popover__coupon_orange': isPriceCoupon,
        'coupon-popover__coupon_green': isShippingCoupon,
      }"
    >
      <!-- 券面额（TODO:后续需替换成组件库组件） -->
      <div 
        ref="couponPopoverDiscountRef"
        class="coupon-popover__discount"
        v-html="couponDiscount"
      >
      </div>
    </div>
    <Tips 
      :language="language"
      :description="language.SHEIN_KEY_PC_29253"
      :timedown="coupon.originInfo.end_date"
      :icon="{}"
    />
  </div>
</template>

<script>
import { template } from '@shein/common-function'

import Tips from './Tips.vue'

const CouponTypeEnum = {
  // 金额券
  AmountDiscount: 'AmountDiscount',
  // 百分比折扣券
  PercentDiscount: 'PercentDiscount',
  // 运费减免券
  Shipping: 'Shipping',
  // 免运费券
  FreeShipping: 'FreeShipping',
}

export default {
  name: 'CouponPopover',
  components: {
    Tips,
  },
  props: {
    language: {
      type: Object,
      default: () => {}
    },
    coupon: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      couponType: '',
      couponDiscount: ''
    }
  },
  computed: {
    isPriceCoupon() {
      return [
        CouponTypeEnum.AmountDiscount,
        CouponTypeEnum.PercentDiscount,
      ].includes(this.couponType)
    },
    isShippingCoupon() {
      return [
        CouponTypeEnum.FreeShipping,
        CouponTypeEnum.Shipping,
      ].includes(this.couponType)
    },
  },
  watch: {
    coupon: {
      handler() {
        this.initInfo()
      },
      immediate: true,
      deep: true
    },
  },
  methods: {
    template,
    initInfo() {
      this.couponType = this.getCouponType()
      this.couponDiscount = this.getCouponDiscount()
      this.updateStyle()
    },
    getCouponType() {
      const { 
        real_type_id, 
        shipping_discount_type, 
        apply_for, 
        coupon_type_id 
      } = this.coupon.originInfo || {}
      
      if([53, 55].includes(+real_type_id) && [9].includes(+apply_for)) {
        if (+shipping_discount_type === 0) {
          // 付费会员、超省卡免邮券
          return CouponTypeEnum.FreeShipping
        } else if (+shipping_discount_type === 1) {
          // 付费会员、超省卡部分邮费减免券
          return CouponTypeEnum.Shipping
        }
      } else {
        if ([5, 6].includes(+apply_for)) {
          // 免邮
          return CouponTypeEnum.FreeShipping
        } else if (+coupon_type_id == 1) {
          // 减金额: coupon_type_id=1
          return CouponTypeEnum.AmountDiscount
        } else {
          // 百分比折扣
          return CouponTypeEnum.PercentDiscount
        }
      }
    },
    getCouponDiscount() {
      let discount = '', discountLanguage = ''
      switch (this.couponType) {
        case CouponTypeEnum.AmountDiscount:
        case CouponTypeEnum.PercentDiscount:
        case CouponTypeEnum.Shipping: {
          const { calculateInfo, originInfo } = this.coupon || {}
          const { couponRangeId } = calculateInfo || {}
          const { other_coupon_rule = [] } = originInfo || {}
          const rule = other_coupon_rule.find(rule => +rule.coupon_range_id === +couponRangeId)
          let value
          if (this.couponType === CouponTypeEnum.PercentDiscount) {
            value = `${rule.newPrice.amount}%`
          } else {
            value = rule.newPrice.amountWithSymbol
          }
          discount = `<span style="font-size:14px">${value}</span>`
          discountLanguage = this.language.SHEIN_KEY_PC_15957
          break
        }
        case CouponTypeEnum.FreeShipping:
          discountLanguage = this.language.SHEIN_KEY_PC_15911
          break
      }
      return this.template(
        discount,
        discountLanguage
      )
    },
    updateStyle() {
      const { scrollWidth, clientWidth } = this.$refs.couponPopoverDiscountRef || {}
      if (scrollWidth > clientWidth) {
        this.$refs.couponPopoverDiscountRef.style.fontSize = '10px' 
      }
    }
  }
}
</script>
<style lang="less" scoped>
.coupon-popover {
  display: flex;
  align-items: center;
  font-family: SF UI Text;
  &__coupon {
    width: 76px;
    height: 41px;
    padding: 0 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1px;
    position: relative;
    border: 0.5px solid rgba(194, 60, 22, 0.16);
    font-size: 14px;
    font-weight: 700;
    background-size: 100% 100%;

    &_orange {
      color: #FF6F46;
      background-image: url('/she_dist/images/checkout/activity_popover_price_coupon-cb49439663.png');
    }

    &_green {
      color: #169E00;
      background-image: url('/she_dist/images/checkout/activity_popover_shipping_coupon-7802811eb6.png');
    }
  }

  &__discount {
    font-size: 12px;
    line-height: 1.15;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-align: center;
  }
}
</style>
