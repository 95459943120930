const analysisCoupon = {
  // ShowMoreDeal() {
  //     sa('send', { activity_name: 'expose_moredeal' })
  //     ga(GB_ANALYSIS_GA_SEND, {
  //         hitType: 'event',
  //         eventCategory: '下单页',
  //         eventAction: 'ShowMoreDeal'
  //     })
  // },
  // SelectMoreDeal() {
  //     sa('send', { activity_name: 'click_moredeal' })
  //     ga(GB_ANALYSIS_GA_SEND, {
  //         hitType: 'event',
  //         eventCategory: '下单页',
  //         eventAction: 'SelectMoreDeal'
  //     })
  // },
  click_moredealoff() {
    sa('send', { activity_name: 'click_moredealoff' })
  },
  // ClickViewCoupon() {
  //     sa('send', { activity_name: 'expose_moredealcoupondetail' })
  //     ga(GB_ANALYSIS_GA_SEND, {
  //         hitType: 'event',
  //         eventCategory: '下单页',
  //         eventAction: 'ClickViewCoupon'
  //     })
  // },
  // ClickMoreDealRule() {
  //     ga(GB_ANALYSIS_GA_SEND, {
  //         hitType: 'event',
  //         eventCategory: '下单页',
  //         eventAction: 'ClickMoreDealRule'
  //     })
  // },
  // ApplyCoupons(val, type = 0) {
  //     ga(GB_ANALYSIS_GA_SEND, {
  //         hitType: 'event',
  //         eventCategory: '下单页',
  //         eventAction: 'ApplyCoupons',
  //         eventLabel: val,
  //         eventValue: type,
  //     })
  // },
  clickCancelCoupon(opt) {
    if (opt.from == 'couponList') {
      sa('send', { activity_name: 'click_popup_sidebarcancelcoupon' })
      return
    }
    sa('send', {
      activity_name: 'click_coupon_code_cancel',
      activity_param: '',
    })
  },
}

export default analysisCoupon
