<template>
  <div
    v-expose="{
      id: '1-11-1-198',
      data: {
        is_choosed: isPointsApplicationSuccess ? 1 : 0,
        is_folded: isFolded,
      }
    }"
    class="check-coupon assets-item__effiency"
  >
    <div class="indicates">
      <span class="assets-item-title">
        <span>{{ language.SHEIN_KEY_PC_14742 }}</span>
        <i style="font-style: normal">
          ({{ language.SHEIN_KEY_PC_14540 }}: {{ checkoutInfoPoint.total }})
        </i>
      </span>
      <s-popover
        class="assets-icon-tip"
        placemen="bottom"
        trigger="hover"
        :content="pointsTips"
        @opened="handlePointsOpen"
      >
        <template #reference>
          <sui_icon_doubt_16px_1
            v-expose="{ id: '1-11-1-136' }"
            class="assets-icon-tip__icon"
            size="14px"
            color="#767676"
          />
        </template>
      </s-popover>
    </div>
    <div class="apply-box she-clearfix">
      <div
        class="c-input she-fl c-input-points"
        :class="{ 'input-points__all': showAllApply }"
      >
        <s-input
          v-if="checkCalculatePoints && !status.loading.points"
          v-model="checkout.mall_caculate_info.points"
          type="text"
          readonly
          onfocus="this.removeAttribute('readonly');"
          disabled="true"
          :aria-label="language.SHEIN_KEY_PC_14742"
        />
        <s-input
          v-else
          v-model="pointsData"
          type="text"
          readonly
          onfocus="this.removeAttribute('readonly');"
          aria-describedby="points-ada-tip"
          :aria-label="language.SHEIN_KEY_PC_14742"
          :placeholder="placeholderTxt"
          :class="[{ 'input-error-bg': status.error.points }]"
          :disabled="
            status.hasAddress == 0 ||
              checkCalculatePoints ||
              !checkoutInfoPoint ||
              checkPoints
              ? true
              : false
          "
          :clearable="true"
          onkeyup="this.value=this.value.replace(/\D/g,'')"
          @input="setMaxPoint"
          @focus="isFocus = true"
          @blur="isFocus = false"
          @click="inputClick('points')"
        >
          <!-- 输入框内部的右侧 全部申请的文案-->
          <template
            v-if="showAllApply"
            #suffix
          >
            <span
              class="point-apply-effiency"
              @click="applyUseAll('points')"
            >
              {{ language.SHEIN_KEY_PC_16662 }}
            </span>
          </template>
        </s-input>
      </div>

      <s-button
        v-if="status.hasAddress == 0 || checkPoints"
        class="she-fr apply-box-btn"
        disabled
        :type="['H36PX']"
        :min-width="'96px'"
        :max-width="'131px'"
      >
        {{ language.SHEIN_KEY_PC_14744 }}
      </s-button>
      <s-button
        v-else
        :type="['H36PX', status.loading.points ? 'primary' : 'default']"
        :min-width="'96px'"
        :max-width="'131px'"
        class="she-fr apply-box-btn"
        @click="
          apply({
            type: 'points',
            isUse: checkCalculatePoints ? 1 : 0,
            byUser: 1,
          })
        "
      >
        <template v-if="status.loading.points">
          <div class="la-ball-pulse la-ball-pulse-white">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </template>
        <template v-else>
          <!-- 按钮文案 移除积分的意思 -->
          <template v-if="checkCalculatePoints">
            {{ language.SHEIN_KEY_PC_15055 }}
          </template>
          <!-- 按钮文案 使用积分的意思 -->
          <template v-else>
            {{ language.SHEIN_KEY_PC_14744 }}
          </template>
        </template>
      </s-button>
    </div>

    <!-- 免邮差额提示 -->
    <CheckoutFreeShipDiffTip
      v-if="hasShipPriceDiff.length"
      class="assets-item__tip"
      :has-ship-price-diff="hasShipPriceDiff"
      :checkout="checkout"
      :language="language"
    />

    <!-- 由积分引起免邮不可用 -->
    <p
      v-show="status.error.points"
      id="points-ada-tip"
      class="error-tip assets-item__tip"
      tabindex="0"
    >
      <span v-if="status.error.pointsCode == 100001">
        <!-- 超过最大积分使用限额 -->
        {{ template(maxAvailablePoint, language.SHEIN_KEY_PC_14700) }}
      </span>
      <span v-else-if="status.error.pointsCode == 100002">
        <!-- 默认情况 -->
        {{ language.SHEIN_KEY_PC_14694 }}
      </span>
      <span v-else>
        <!-- 默认情况 -->
        {{ language.SHEIN_KEY_PC_14696 }}
      </span>
    </p>

    <!-- 底部绿色文案 积分兑换成功 -->
    <p
      v-cloak
      v-show="isPointsApplicationSuccess"
      class="success-tip assets-item__tip"
      tabindex="0"
    >
      {{ language.SHEIN_KEY_PC_14695 }}
    </p>

    <!-- 输入框底部最大额度可以使用的积分提示-->
    <p
      v-if="status.hasAddress && checkoutInfoPoint"
      class="point-link assets-item__tip"
      tabindex="0"
    >
      <span>
        {{ language.SHEIN_KEY_PC_14745 }}:
        {{ maxAvailablePoint ? +maxAvailablePoint : 0 }}
      </span>
    </p>
    <!-- 使用积分规则说明 -->
    <template v-if="!IS_RW && status.hasAddress && checkoutInfoPoint.total">
      <!-- 当前下单商品全都不可用积分 -->
      <p
        v-if="isShowPointRuleUseReason.state === pointRatioUseState.UNABLE"
        class="point-use-rule-tip"
      >
        {{ language.SHEIN_KEY_PC_28631 }}
      </p>
      <!-- 当前选择的全部商品可用的折扣一致 -->
      <p
        v-else-if="
          isShowPointRuleUseReason.state === pointRatioUseState.ALL_SAME
        "
        class="point-use-rule-tip"
      >
        {{
          template(
            isShowPointRuleUseReason.discountRate,
            language.SHEIN_KEY_PC_28634
          )
        }}
      </p>
      <!-- 当前下单商品中的商品折扣比例不一样 -->
      
      <p
        v-else-if="
          isShowPointRuleUseReason.state ===
            pointRatioUseState.MULTIPLE_TYPES_PART_USE
        "
        v-expose="{ id: '1-11-1-174' }"
        class="point-use-rule-tip"
      >
        <span>{{ language.SHEIN_KEY_PC_28690 }}</span>
        <span
          class="point-use-rule-tip__view-more-btn"
          @click="viewLimitReason"
        >
          {{ language.SHEIN_KEY_PC_28693 }}
          <sui_icon_more_right_12px
            v-expose="{ id: '1-11-1-136' }"
            size="12px"
            color="#2D68A8"
          />
        </span>
      </p>
      <p
        v-else
        v-expose="{ id: '1-11-1-174' }"
        class="point-use-rule-tip"
      >
        <span>{{ language.SHEIN_KEY_PC_28691 }}</span>
        <span
          class="point-use-rule-tip__view-more-btn"
          @click="viewLimitReason"
        >
          {{ language.SHEIN_KEY_PC_28693 }}
          <sui_icon_more_right_12px
            size="12px"
            color="#2D68A8"
          />
        </span>
      </p>
    </template>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { htmlDecode, template } from '@shein/common-function'
import { tap, expose } from 'public/src/pages/common/analysis/directive.js'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import checkoutMixin from 'public/src/pages/checkout/checkoutMixin.js'
import { handleLimitGoods } from '../../config/tools'

import CheckoutFreeShipDiffTip from './CheckoutFreeShipDiffTip.vue'
import { sui_icon_doubt_16px_1, sui_icon_more_right_12px } from '@shein-aidc/icon-vue3'

daEventCenter.addSubscriber({ modulecode: '1-11-1' })

// 积分对于不同的商品，使用规则是不同的
const pointRatioUseState = {
  NOT_POINT: -1, // 当前账户没有积分
  UNABLE: 0, // 当前选择的商品的可使用积分比例系数都是0
  ALL_SAME: 1, // 当前选择的商品的可使用积分比例系数都是一样的，但是大于0
  MULTIPLE_TYPES_PART_USE: 2, // 当前选择的商品的可使用积分比例是多种的，但是有部分商品是不能使用的，是0
  MULTIPLE_TYPES_ALL_USE: 3, // 当前选择的商品的可使用积分比例是多种的，并且都不是0
}

export default {
  directives: { tap, expose },
  components: {
    CheckoutFreeShipDiffTip,
    sui_icon_doubt_16px_1,
    sui_icon_more_right_12px,
  },
  mixins: [checkoutMixin],
  props: {
    isFolded: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      isFocus: false,
      pointRatioUseState,
    }
  },
  computed: {
    ...mapState([
      'freeShipTip',
      'status',
      'checkout',
      'caculateData',
      'pointLimitGoods',
      'language',
      'locals',
    ]),
    IS_RW() {
      return this.locals.IS_RW
    },
    maxAvailablePoint() {
      return this.IS_RW
        ? this.caculateInfo.maxAvailablePoint
        : this.caculateInfo.maxAvailablePoint2
    },
    pointsTips() {
      return this.IS_RW
        ? this.language.SHEIN_KEY_PC_14743
        : this.template(
          this.caculateInfo.point_max_discount_rate,
          this.language.SHEIN_KEY_PC_28629
        )
    },
    checkPoints() {
      return this.checkout?.results?.points?.info?.point?.total <= 0
    },
    caculateInfo() {
      return this.checkout?.mall_caculate_info || {}
    },
    checkoutInfoPoint() {
      return this.checkout?.results?.points?.info?.point
    },
    checkCalculatePoints() {
      return this.checkout.mall_caculate_info.points > 0
    },
    isPointsApplicationSuccess() {
      return this.caculateInfo?.pointPrice?.amount > 0
    },
    pointsData: {
      get() {
        return this.$store.state.pointsData
      },
      set(newVal) {
        this.$store.commit('updateCheckoutState', {
          key: 'pointsData',
          val: newVal,
        })
      },
    },
    hasShipPriceDiff() {
      const { point, other, otherType } = this.freeShipTip
      let newShipList = []
      if (point.length) newShipList = point
      if (otherType == 'points' && other.length)
        newShipList = newShipList.concat(other)
      return newShipList
    },
    inputDisabled() {
      return (
        (this.checkCalculatePoints && !this.status.loading.points) ||
        (this.status.hasAddress == 0 ||
        this.checkCalculatePoints ||
        !this.checkoutInfoPoint ||
        this.checkPoints
          ? true
          : false)
      )
    },
    showAllApply() {
      const canApply =
        +this.maxAvailablePoint > 0 && +this.caculateData.points == 0
      if (this.inputDisabled || this.isFocus || !canApply || this.pointsData)
        return false
      return true
    },
    placeholderTxt() {
      if (this.checkoutInfoPoint.total === 0) {
        return this.language.SHEIN_KEY_PC_28667
      }

      return this.caculateInfo.maxAvailablePoint
        ? String(this.caculateInfo.maxAvailablePoint)
        : ''
    },
    isShowPointRuleUseReason() {
      if (!this.pointLimitGoods.length) {
        return { state: pointRatioUseState.UNABLE }
      }

      const discountRateOutMap = this.pointLimitGoods?.reduce((acc, curr) => {
        if (curr?.discountRate) {
          acc[curr.discountRate] = (acc[curr.discountRate] || 0) + 1
        }
        return acc
      }, {})
      const discountRateOutKeys = Object.keys(discountRateOutMap)

      if (discountRateOutKeys?.length === 1) {
        return {
          state: discountRateOutMap['0%']
            ? pointRatioUseState.UNABLE
            : pointRatioUseState.ALL_SAME,
          discountRate: this.pointLimitGoods[0]?.discountRate,
        }
      }

      if (
        Object.keys(discountRateOutMap).length === 2 &&
        discountRateOutMap['0%']
      ) {
        return { state: pointRatioUseState.MULTIPLE_TYPES_PART_USE }
      }
      return { state: pointRatioUseState.MULTIPLE_TYPES_ALL_USE }
    },
    cartSubInfos() {
      return this.caculateInfo?.cart_sub_infos || []
    },
    carts() {
      return this.checkout?.results?.carts?.carts || []
    },
  },
  watch: {
    cartSubInfos: {
      deep: true,
      immediate: true,
      handler(v) {
        if (v.length) this.handleLimitData()
      },
    },
    carts: {
      deep: true,
      immediate: true,
      handler(v) {
        if (v.length) this.handleLimitData()
      },
    },
  },
  methods: {
    ...mapMutations([
      'updateCheckoutState',
      'changeParamsStatus',
      'assignState',
    ]),
    htmlDecode,
    template,
    handleLimitData() {
      const { pointLimitGoods } = handleLimitGoods({
        mallStoreInfos: this.cartSubInfos,
        carts: this.carts,
        type: 'point', // 积分
        countryId: this.checkout?.default_address?.countryId,
      })
      this.assignState({ pointLimitGoods })
    },
    viewLimitReason() {
      // 积分详情入口点击
      daEventCenter.triggerNotice({
        daId: '1-11-1-175',
      })

      this.changeParamsStatus({
        type: 'freezTipsAndGoods',
        params: {
          show: true,
          type: 'point',
          dialogTitle: this.language.SHEIN_KEY_PC_28692,
        },
      })
    },
    handlePointsOpen() {
      daEventCenter.triggerNotice({
        daId: '1-11-1-137',
      })
    },
    inputClick() {},
    // points input max
    setMaxPoint() {
      this.status.error.points = 0

      if (this.pointsData.trim() != '' && this.pointsData >= 0) {
        this.updateCheckoutState({
          key: 'pointsData',
          val: this.pointsData * 1,
        })
      }
      if (this.pointsData * 1 > this.maxAvailablePoint * 1) {
        this.updateCheckoutState({
          key: 'pointsData',
          val: this.maxAvailablePoint,
        })
        this.status.error.maxAvailablePoint = 1
      } else {
        this.status.error.maxAvailablePoint = 0
      }
    },
    applyUseAll() {
      this.updateCheckoutState({
        key: 'pointsData',
        val: this.maxAvailablePoint,
      })

      window.checkoutEventCenter.emit('click-apply-assets', {
        type: 'points',
        isUseAll: true,
        byUser: 1,
      })

      daEventCenter.triggerNotice({
        daId: '1-11-1-138',
      })
    },
    apply(obj) {
      window.checkoutEventCenter.emit('click-apply-assets', obj)
    },
  },
}
</script>

<style lang="less">
.input-points__all {
  .sui-input__suffix-inner {
    min-width: 60px;
    max-width: 80px;
  }
}
.point-apply-effiency {
  color: @sui_color_link;
  font-size: 12px;
  cursor: pointer;

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
  white-space: normal;
}
.point-use-rule-tip {
  margin-top: 5px;
  font-size: 12px;
  color: var(--sui-color-gray-dark-2, #666);
  &__view-more-btn {
    margin-left: 6px;
    color: var(--sui-color-link, #2d68a8);
    font-size: 12px;
    cursor: pointer;
  }
}
.success-tip{
  margin-top: 5px;
  // margin-bottom: 5px;
  color: @sui_color_success;
}
.point-link{
  margin-top: 5px;
  color: #767676;
  display: inline-block;
  text-decoration: none;
  a {
    cursor: pointer;
    color: @sui_color_link;
  }
}
.c-order-summary .check-coupon{
		margin: 5px 0 30px 0;
		.apply-box{
			margin-top: 3px;
			.c-input{
				width: calc(~"65% - 10px");
        input {
          text-overflow: ellipsis;
        }
				input:disabled{
					color: #999;

					/* ipad下safari的input disabled字体不明显兼容 */
					opacity: 1;
					-webkit-text-fill-color: #999;
					-moz-text-fill-color: #999;
					-ms-text-fill-color: #999;
					-o-text-fill-color: #999;
				}
        input{
          &::-webkit-input-placeholder,
          &:-moz-placeholder,
          &::-moz-placeholder,
          &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: #bbbbbb;
          }
        }
				input.input-error-bg{
					border-color: @sui_color_unusual;
					background-color: @sui_color_unusual_bg;
				}
			}
			>a{
				width: 35%;
				display: block;
			}
		}

		.apply-box{
			.card-num{
				width: calc(~"47% - 10px");
			}
			.password{
				width: 17%;
				.margin-l(1%);
				margin-top: 0;
			}
			>a{
				width: 35%;
				display: block;
			}
			.la-ball-pulse{
				>div{
					background-color:#fff;
				}
			}
		}
		.error-tip{
			margin-top: 5px;
			color: @sui_color_unusual;
		}
		.warn-tip{
			margin-top: 5px;
		}
		.success-tip{
			margin-top: 5px;
			// margin-bottom: 5px;
			color: @sui_color_success;
		}
		.point-link{
			margin-top: 5px;
			color: #767676;
			display: inline-block;
			text-decoration: none;
			a {
				cursor: pointer;
				color: @sui_color_link;
			}
		}
		.first-order-off{
			margin-top: 10px;
		}
		.flash-red #message {
			color: red;
    }
    .red-point-tips {
      a{ position: relative ; }
      a:after {
        content: '';
        display: block;
        position: absolute;
        width: 4px;
        height: 4px;
        background: #FF411C;
        border-radius: 50%;
        top: 0;
        .right(-8px);
      }
    }
		.pick-gift{
			background: #FFF7F0;
			border-radius: 2px;
			margin-top: 15px;
			padding: 12px 10px 10px;
			.flexbox();
			.align-center();
			.space-between();
			.pick-gift-left{
				.pick-gift-title{
					font-size: 12px;
					font-weight: bold;
					color: #222222;
					line-height: 14px;
					text-transform: capitalize;
					padding-bottom: 5px;
				}
				.pick-gift-desc{
					font-size: 12px;
					color: #222;
					font-weight: normal;
				}
			}
			.pick-gift-right{
				span{
					font-size: 12px;
					color: #222;
					font-weight: bold;
					cursor: pointer;
				}
			}
		}
	}
@media (max-width: 1440px) {
	.c-order-summary{
		.check-coupon{
			margin: 0 0 16px 0;
		}
		.check-coupon-bottom {
			margin-bottom: 0;
		}
	}
}
</style>
