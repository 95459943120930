<template>
  <li class="summary-discount-item">
    <GoodsList 
      :list="goodsList"
    />

    <div :class="['summary-discount-item__desc', { 'summary-discount-item__other-layout': type === 'coupon' }]">
      <div
        v-if="type === 'coupon'"
        class="summary-discount-item__desc-coupon"
      >
        <span class="summary-discount-item__coupon-text">{{ couponCodeText }}</span>
      </div>
      <span v-else>
        <em v-html="activity"></em>
        |
      </span>
      <span>
        {{ saveTips }}
      </span>
    </div>
    <!-- 活动的解释，目前只有typeId 33品类首津有 -->
    <div
      v-if="hasExplain"
      class="summary-discount-item__explain"
    >
      {{ language.SHEIN_KEY_PC_30875 }}
    </div>
  </li>
</template>

<script setup>
import { computed } from 'vue'
import { template } from '@shein/common-function'

import GoodsList from './GoodsList.vue'

const saveTips = computed(() => {
  return template(props.amount?.amountWithSymbol, props.language?.SHEIN_KEY_PC_29866)
})

const couponCodeText = computed(() => {
  return template(props.couponCode, props.language?.SHEIN_KEY_PC_29867)
})

const props = defineProps({
  language: {
    type: Object,
    default: () => {}
  },
  goodsList: {
    type: Array,
    default: () => []
  },
  type: { // promotion、coupon
    type: String,
    default: 'promotion'
  },
  activity: {
    type: String,
    default: ''
  },
  amount: {
    type: Object,
    default: () => {}
  },
  // 优惠卷的code码
  couponCode: {
    type: String,
    default: ''
  },
  hasExplain: {
    type: Boolean,
    default: false,
  }
})
</script>

<style lang="less" scoped>
.summary-discount-item {
  margin-top: 16px;
  &:first-child {
    margin-top: 0;
  }
  &__desc {
    margin-top: 8px;
    color: @sui_color_gray_dark3;
    font-size: 12px;
  }
  &__other-layout {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 2px;
    &>:last-child {
      flex-shrink: 0;
    }
  }
  &__desc-coupon {
    display: flex;
    align-items: center;
    padding: 2px 4px 2px 10px;
    margin-right: 2px;
    background-repeat: no-repeat;
    background-image: url(/she_dist/images/checkout/discount_counpon_code_bg-787228b991.png);
    background-size: 100% 100%;
    color: var(---sui_color_discount, #FA6338);
  }
  &__coupon-text {
    line-height: 1.2;
  }
  &__explain {
    margin-top: 6px;
    color: var(---sui_color_gray_alpha30, rgba(0, 0, 0, 0.30));
    font-size: 10px;
  }
}
</style>
