<template>
  <div class="checkout-ship-address">
    <shipping-address-new-card 
      v-if="!IS_RW && newAddressShipByAbt"
      :language="language"
      :adr-name-verified-title-tip="adrNameVerifiedTitleTip"
      :status="status"
      :checkout="checkout"
      :second-language="secondLanguage"
      :source-gray="sourceGray"
      :kr-copywrite-tip="krCopywriteTip"
      @click-tw-jump-url="clickTwJumpUrl"
      @click-address-tips="clickAddressTips"
    />
    <shipping-address-card 
      v-else
      :language="language"
      :adr-name-verified-title-tip="adrNameVerifiedTitleTip"
      :status="status"
      :checkout="checkout"
      :second-language="secondLanguage"
      :source-gray="sourceGray"
      :kr-copywrite-tip="krCopywriteTip"
      @click-tw-jump-url="clickTwJumpUrl"
      @click-address-tips="clickAddressTips"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import ShippingAddressCard from '../../../vue_tpls/ShippingAddressCard.vue'
import ShippingAddressNewCard from '../../../vue_tpls/ShippingAddressNewCard.vue'

import { daEventCenter } from 'public/src/services/eventCenter/index'
import { TRANSPORT_TYPE_SHOP } from 'public/src/js/constants'

daEventCenter.addSubscriber({ modulecode: '1-11-1' })

export default {
  name: 'ShippingAddress',
  components: {
    ShippingAddressCard,
    ShippingAddressNewCard,
  },
  props: {
    secondLanguage: {
      type: Object,
      default: () => {}
    },
    sourceGray: {
      type: Array,
      default: () => []
    },
    krCopywriteTip: {
      type: String,
      default: ''
    },
    adrNameVerifiedTitleTip: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      TRANSPORT_TYPE_SHOP,
    }
  },
  computed: {
    ...mapState([
      'checkout',
      'language',
      'status',
      'locals',
    ]),
    ...mapGetters([
      'newAddressShipByAbt',
    ]),
    IS_RW() {
      return this.locals.IS_RW
    },
  },
  methods: {
    clickTwJumpUrl(ev){
      //点击a标签跳转时的埋点
      if( ev?.target?.tagName == 'A' ){
        daEventCenter.triggerNotice({ daId: '1-11-1-109' })
      }
    },
    clickAddressTips(e) {
      if (e.target.tagName != 'A') return
      window.checkoutEventCenter.emit('click-address-tips')
    },
  }
}
</script>
