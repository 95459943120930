<template>
  <div
    v-expose="{
      id: '1-11-1-199',
      data: {
        is_choosed: isGifcardUsed ? 1 : 0,
        is_folded: isFolded,
      }
    }"
    class="check-coupon assets-item__effiency"
  >
    <div
      v-show="caculateInfo && !caculateInfo.giftCardUsedPrice"
      class="gift-card-apply"
    >
      <div class="indicates">
        <span class="assets-item-title">
          {{ language.SHEIN_KEY_PC_15570 }}
        </span>
        <s-popover
          class="assets-icon-tip"
          placemen="bottom"
          trigger="hover"
          :content="language.SHEIN_KEY_PC_15592"
        >
          <template #reference>
            <sui_icon_doubt_16px_1
              class="assets-icon-tip__icon"
              size="14px"
              color="#767676"
            />
          </template>
        </s-popover>
        <span
          v-if="isAllStore && IS_RW"
          class="only-for-self"
        >
          {{
            SiteUID.endsWith('th')
              ? language.SHEIN_KEY_PC_22764
              : language.SHEIN_KEY_PC_22763
          }}
        </span>
      </div>
      <div class="apply-box she-clearfix">
        <form class="checkout-giftcard-form">
          <div class="c-input card-num she-fl c-input-giftcard">
            <s-input
              v-model="giftcard.num"
              type="text"
              readonly
              onfocus="this.removeAttribute('readonly');"
              aria-describedby="giftcard-ada-tip"
              name=""
              :placeholder="language.SHEIN_KEY_PC_15572"
              autocomplete="off"
              maxlength="20"
              :aria-label="language.SHEIN_KEY_PC_15570"
            />
          </div>
          <div class="c-input password she-fl c-input-pin">
            <s-input
              v-model="giftcard.pin"
              type="text"
              readonly
              onfocus="this.removeAttribute('readonly');this.type='password';"
              aria-describedby="giftcard-ada-tip"
              name=""
              :placeholder="language.SHEIN_KEY_PC_15573"
              autocomplete="off"
              :aria-label="language.SHEIN_KEY_PC_15573"
            />
          </div>
        </form>
        <s-button
          :type="['H36PX', giftcard.loading ? 'primary' : 'default']"
          :min-width="'96px'"
          :max-width="'131px'"
          class="she-fr apply-box-btn"
          @click="applyGiftcard"
        >
          <div v-if="!giftcard.loading">
            {{ language.SHEIN_KEY_PC_14744 }}
          </div>
          <div
            v-else
            class="la-ball-pulse"
          >
            <div></div>
            <div></div>
            <div></div>
          </div>
        </s-button>
      </div>
      <p
        v-show="giftcard.err"
        id="giftcard-ada-tip"
        class="error-tip assets-item__tip"
        tabindex="0"
      >
        <span>{{ giftcard.err }}</span>
      </p>
    </div>
    <div
      v-if="caculateInfo.giftcard && caculateInfo.giftcard.giftCardUsedPrice"
      class="gift-card-info assets-item__tip"
      :class="{'check-coupon-bottom': !!giftCardLimitGoods.length }"
    >
      <div class="info-ele">
        <span>{{ caculateInfo.giftcard.giftcardNo }}</span>
      </div>
      <div class="info-ele">
        <span>{{ language.SHEIN_KEY_PC_15586 }}:</span>
        <span>
          <em>{{ caculateInfo.giftcard.giftcardBalancePrice }}</em>
          <em>({{ caculateInfo.giftcard.giftcardBalanceUSDPrice .amountWithSymbol }})</em>
        </span>
      </div>
      <s-button 
        :type="['H28PX']"
        class="card-remove" 
        @click="cancelGiftCard"
      >
        {{ language.SHEIN_KEY_PC_15585 }}
      </s-button>
    </div>

    <div
      v-if="giftCardLimitGoods.length"
      class="gift-limit-reason assets-item__tip"
    >
      <span
        class="reason-more"
        @click="viewLimitReason"
      >
        {{ language.SHEIN_KEY_PC_26079 }}
      </span>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { sui_icon_doubt_16px_1 } from '@shein-aidc/icon-vue3'


export default {
  components: {
    sui_icon_doubt_16px_1,
  },
  props: {
    giftcard: {
      type: Object,
      default: () => {
        return {}
      },
    },
    isAllStore: {
      type: Boolean,
      default: () => {
        return false
      },
    },
    isFolded: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    ...mapState([
      'giftCardLimitGoods',
      'checkout',
      'language',
      'locals',
    ]),
    IS_RW() {
      return this.locals.IS_RW
    },
    SiteUID() {
      return this.locals.SiteUID
    },
    caculateInfo() {
      return this.checkout?.mall_caculate_info || {}
    },
    isGifcardUsed() {
      return Number(this.caculateInfo?.giftcard?.giftCardUsedPrice?.amount) > 0
    }
  },
  methods: {
    ...mapMutations(['changeParamsStatus']),
    applyGiftcard() {
      window.checkoutEventCenter.emit('apply-giftcard')
    },
    cancelGiftCard() {
      window.checkoutEventCenter.emit('cancel-giftcard')
    },
    viewLimitReason() {
      this.changeParamsStatus({
        type: 'freezTipsAndGoods',
        params: {
          show: true,
          type: 'giftCard',
          dialogTitle: this.language.SHEIN_KEY_PC_22901
        },
      })
    },
  },
}
</script>

<style lang="less">
.assets-icon-tip__icon {
  margin-left: 5px;
}
.gift-limit-reason {
  margin-top: 4px;

  .reason-more {
    color: @sui_color_link;
    cursor: pointer;
  }
}
.gift-card-info{
		position: relative;
		background: @sui_color_gray_weak2;
    padding: 10px;
    .info-ele{
      line-height: 22px;
		*{
				display: inline-block;
			}
    }
    .card-remove{
      position: absolute;
      top: 20px;
      .right(10px);
      // padding: 5px;
    }
	}
  .check-coupon{
		margin: 5px 0 30px 0;
		.apply-box{
			margin-top: 3px;
			.c-input{
				width: calc(~"65% - 10px");
				input:disabled{
					color: #999;

					/* ipad下safari的input disabled字体不明显兼容 */
					opacity: 1;
					-webkit-text-fill-color: #999;
					-moz-text-fill-color: #999;
					-ms-text-fill-color: #999;
					-o-text-fill-color: #999;
				}
        input{
          &::-webkit-input-placeholder,
          &:-moz-placeholder,
          &::-moz-placeholder,
          &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: #bbbbbb;
          }
        }
				input.input-error-bg{
					border-color: @sui_color_unusual;
					background-color: @sui_color_unusual_bg;
				}
			}
			>a{
				width: 35%;
				display: block;
			}
		}

		.apply-box{
			.card-num{
				width: calc(~"47% - 10px");
			}
			.password{
				width: 17%;
				.margin-l(1%);
				margin-top: 0;
			}
			>a{
				width: 35%;
				display: block;
			}
			.la-ball-pulse{
				>div{
					background-color:#fff;
				}
			}
		}
		.error-tip{
			margin-top: 5px;
			color: @sui_color_unusual;
		}
		.warn-tip{
			margin-top: 5px;
		}
		.success-tip{
			margin-top: 5px;
			// margin-bottom: 5px;
			color: @sui_color_success;
		}
		.point-link{
			margin-top: 5px;
			color: #767676;
			display: inline-block;
			text-decoration: none;
			a {
				cursor: pointer;
				color: @sui_color_link;
			}
		}
		.first-order-off{
			margin-top: 10px;
		}
		.flash-red #message {
			color: red;
    }
    .red-point-tips {
      a{ position: relative ; }
      a:after {
        content: '';
        display: block;
        position: absolute;
        width: 4px;
        height: 4px;
        background: #FF411C;
        border-radius: 50%;
        top: 0;
        .right(-8px);
      }
    }
		.pick-gift{
			background: #FFF7F0;
			border-radius: 2px;
			margin-top: 15px;
			padding: 12px 10px 10px;
			.flexbox();
			.align-center();
			.space-between();
			.pick-gift-left{
				.pick-gift-title{
					font-size: 12px;
					font-weight: bold;
					color: #222222;
					line-height: 14px;
					text-transform: capitalize;
					padding-bottom: 5px;
				}
				.pick-gift-desc{
					font-size: 12px;
					color: #222;
					font-weight: normal;
				}
			}
			.pick-gift-right{
				span{
					font-size: 12px;
					color: #222;
					font-weight: bold;
					cursor: pointer;
				}
			}
		}
	}
@media (max-width: 1440px) {
	.c-order-summary{
		.check-coupon{
			margin: 0 0 16px 0;
		}
		.check-coupon-bottom {
			margin-bottom: 0;
		}
	}
}
</style>
