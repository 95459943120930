<template>
  <swiper-container 
    v-if="rightConfigList && rightConfigList.length && rightConfigList.length > 1"
    init="false" 
    class="prime-options__goods"
    @mouseenter="enterEvent"
    @mouseleave="leaveEvent" 
  >
    <swiper-slide 
      v-for="(right, rightIndex) in rightConfigList" 
      :key="rightIndex"
      class="prime-options__goods-item" 
      :class="{'prime-options__goods-item-ar': GB_cssRight}" 
    >
      <span class="prime-options__goods-name">
        {{ right.right_type_name }}
      </span>
      <component
        :is="handleRightIcon(right)"
        v-if="handleRightIcon(right)"
        class="prime-options__goods-icon"
        size="20px"
        color="#ffba97"
      />
    </swiper-slide>
    <!-- eslint-disable vue/no-deprecated-slot-attribute -->
    <div slot="container-start">
      <div
        v-show="showSwiperButton"
        class="prime-options__goods-prev"
        @click.stop="prevEl"
      >
        <Icon
          name="sui_icon_more_left_18px"
          size="16px"
          :is-rotate="GB_cssRight"
        />
      </div>
    </div>
    <div slot="container-end">
      <div
        v-show="showSwiperButton"
        class="prime-options__goods-next"
        @click.stop="nextEl"
      >
        <Icon
          name="sui_icon_more_right_18px"
          size="16px"
          :is-rotate="GB_cssRight"
        />
      </div> 
    </div>
  </swiper-container>
</template>

<script>
import { mapState } from 'vuex'
import { Icon, sui_icon_extrapoints_club_fill_20px, sui_icon_shipping_club_fill_20px, sui_icon_specialprice_club_fill_20px, sui_icon_club_giftbox4_20px } from '@shein-aidc/icon-vue3'
import { register } from 'swiper/element'
import { Navigation } from 'swiper/modules'

typeof window !== 'undefined' && register()


export default {
  name: 'PrimeOptionsGoods',
  components: {
    Icon,
    sui_icon_extrapoints_club_fill_20px,
    sui_icon_shipping_club_fill_20px,
    sui_icon_specialprice_club_fill_20px,
    sui_icon_club_giftbox4_20px,
  },
  data() {
    return {
      primeSwiper: null,
      showSwiperButton: false
    }
  },
  computed: {
    ...mapState(['rightConfigList', 'locals']),
    GB_cssRight() {
      return this.locals.GB_cssRight
    },
  },
  watch: {
    rightConfigList: {
      handler() {
        this.$nextTick(() => {
          if (this.primeSwiper) {
            this.primeSwiper?.swiper?.update()
          } else {
            this.initSwiper()
          }
        })
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    enterEvent() {
      this.showSwiperButton = true
    },
    leaveEvent() {
      this.showSwiperButton = false
    },
    initSwiper() {
      if(typeof window == 'undefined') return

      const swiperParams = {
        slidesPerView: 'auto',
        slidesPerGroup: 1,
        normalizeSlideIndex: false,
        modules: [Navigation],
        navigation: {
          nextEl: '.prime-options__goods-next',
          prevEl: '.prime-options__goods-prev'
        },
      }
      this.primeSwiper = document.querySelector('.prime-options__goods')
      if(this.primeSwiper) {
        Object.assign(this.primeSwiper, swiperParams)
        this.primeSwiper.initialize()
      }
    },
    handleRightIcon(right) {
      // 『SHEIN中央：53840-付费会员积分发放,SHEIN欧洲：付费会员积分发放：53779-,SHEIN美国 ：52078-付费会员积分发放』
      // 积分
      if([53840, 53779, 52078].includes(+right?.right_type_id) || right?.right_type_code == 'MULTIPLE_POINTS') return 'sui_icon_extrapoints_club_fill_20px'
      // 免邮
      if (right?.right_type_id == 53) return 'sui_icon_shipping_club_fill_20px'
      // 折扣
      if (right?.right_type_id == 29) return 'sui_icon_specialprice_club_fill_20px'
      // 会员赠品
      if (right?.right_type_id == 4) return 'sui_icon_club_giftbox4_20px'
      return ''
    },
  }
}
</script>

<style lang="less" scoped>
.prime-options {
  &__goods {
    display: flex;
    max-width: 337px;
    margin-top: 8px;
    flex-wrap: nowrap;
    overflow: hidden;
  }
  &__goods-item {
    flex: 1;
    flex-shrink: 0;
    word-break: break-word;
    min-height: 44px;
    min-width: 104px;
    padding: 8px 10px;
    background: linear-gradient(179.21deg, rgba(252, 226, 211, 0.3) 32.82%, rgba(255, 216, 196, 0.3) 99.32%);
    border-radius: 2px;
    color: @sui_color_club_rosegold_dark3;
    font-size: 12px;
    .margin-r(4px);
    border: 0;
    position: relative;
    &::before {
      position: absolute;
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      bottom: 0;
      .right(0);
      background: url('/she_dist/images/checkout/sui_img_bg_club_bottom@3x-6e3b12dada.png') no-repeat;
      background-size: 100% 100%;
    }
    &::after {
      position: absolute;
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      .left(0);
      background: url('/she_dist/images/checkout/sui_img_bg_club_top@3x-5598445c51.png') no-repeat;
      background-size: 100% 100%;
    }
    &:last-child {
      .margin-r(0);
    }
    img {
      width: 15px;
      height: auto;
    }
  }
  &__goods-item-ar {
    position: relative;
    &::before {
      position: absolute;
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      bottom: 0;
      .right(0);
      background: url('/she_dist/images/checkout/sui_img_bg_club_bottom_ar@3x-436ed26482.png') no-repeat;
      background-size: 100% 100%;
    }
    &::after {
      position: absolute;
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      .left(0);
      background: url('/she_dist/images/checkout/sui_img_bg_club_top_ar@3x-8c4cc10b62.png') no-repeat;
      background-size: 100% 100%;
    }
  }
  &__goods-name {
    display: inline-block;
    width: 85%;
  }
  &__goods-icon {
    position: absolute;
    bottom: 4px;
    right: 4px;
    color: @sui_color_club_rosegold;
  }

  &__goods-prev,
  &__goods-next {
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    background: rgba(255, 255, 255, 0.94);
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08);
    z-index: 2;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  &__goods-next {
    left: auto;
    right: 10px;
  }
}
</style>
