<template>
  <div 
    class="prime-card"
    :class="{
      'prime-card_activity': hasActivity, 
      'prime-card_scale': isScale,
      'prime-card_selected': isSelected,
      'prime-card_auto-renewal': isAutoRenewal
    }"
  >
    <div 
      class="prime-card__name"
      :class="{ 'prime-card__name_auto-renewal': isAutoRenewal }"
    >
      <div 
        class="prime-card__name_triangle"
        :class="{ 'prime-card__triangle_ar': GB_cssRight }"
      ></div>
      <div 
        class="prime-card__name_bg"
        :class="{ 'prime-card__namebg_ar': GB_cssRight }"
      >
        <div v-html="productName">
        </div>
      </div>
    </div>
    <div 
      class="prime-card__right"
      :class="{
        'prime-card__right_activity': !!activityType,
      }"
    >
      <template
        v-for="right in item.right_config_list.slice(0, 3)"
        :key="right.right_type_id"
      >
        <div class="prime-card__right_wrapper">
          <component
            :is="getRightIcon(right)"
            v-if="getRightIcon(right)"
            size="24px"
            color="#873c00"
          />
          <div class="prime-card__right_des">
            {{ right.right_type_name }} 
          </div>
        </div>
        <div class="prime-card__right_divider"></div>
      </template>
    </div>
    <div 
      v-if="!!activityType"
      class="prime-card__activity"
    >
      <div class="prime-card__activity_left">
        <img
          class="prime-card__activity_icon"
          :src="`${PUBLIC_CDN}/she_dist/images/checkout/prime_club_gift-e680a2fa9a.png`" 
        />
        <div 
          class="prime-card__activity_tips"
        >
          {{ activityTips }}
        </div>
      </div>
      <div 
        v-if="countDownTimer?.length"
        class="prime-card__count"
      >
        <div 
          v-for="(text, index) in countDownTimer"
          :key="index"
          class="prime-card__count_item"
        >
          {{ text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { template, CountDown } from '@shein/common-function'
import { sui_icon_extrapoints_club_fill_20px, sui_icon_shipping_club_fill_20px, sui_icon_specialprice_club_fill_20px } from '@shein-aidc/icon-vue3'

const ActivityType = {
  // 折扣活动价
  Discount: 'discount',
  // 买一赠一
  Gift: 'gift'
}

export default {
  name: 'PrimeOptionsCard',
  components: {
    sui_icon_extrapoints_club_fill_20px, 
    sui_icon_shipping_club_fill_20px, 
    sui_icon_specialprice_club_fill_20px,
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    isScale: {
      type: Boolean,
      default: false
    },
    hasActivity: {
      type: Boolean,
      default: false
    },
    isSelected: {
      type: Boolean,
      default: false
    },
    // 是否为可自动续费产品包
    isAutoRenewal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      timer: null,
    }
  },
  computed: {
    ...mapState([
      'checkout',
      'language',
      'locals',
    ]),
    GB_cssRight() {
      return this.locals.GB_cssRight
    },
    PUBLIC_CDN() {
      return this.locals.PUBLIC_CDN
    },
    primeGift() {
      return this.item?.gift_info_list?.filter(item => item.gift_type == 1) || []
    },
    activityType() {
      let type = ''
      // 买一赠一活动
      if (this.primeGift.length > 0) {
        type = ActivityType.Gift
      } else if (this.hasDiscount) {
        // 折扣活动价
        type = ActivityType.Discount
      } 
      
      if (!this.hasActivity && !!type) this.$emit('handleActivityShow', !!type)
      
      return type
    },
    // 折扣活动价
    hasDiscount() {
      // 正式包(product_type=0) & promotion_type 价格类型：非空、非0- 日常价 才展示折扣文案
      return +this.item?.product_type == 0 && !!+this.item?.product_price_info?.promotion_type && (+this.item?.product_price_info?.special_price < +this.item?.product_price_info?.price_local)
    },
    activityTips() {
      switch(this.activityType) {
        case ActivityType.Gift:
          return this.template(
            this.primeGift[0]?.product_cycle_days,
            this.primeGift[0]?.gift_product_price_info?.special_price_with_symbol,
            this.language.SHEIN_KEY_PC_27870
          )
        case ActivityType.Discount:
          return this.template(
            this.item?.product_price_info?.reduce_price_with_symbol,
            this.language.SHEIN_KEY_PC_27871
          )
        default:
          return ''
      }
    },
    // 倒计时
    countDownTimer () {
      if (!this.timer) return []
      const { H: hour, M: minute, S: seconds } = this.timer?.timeObj || {}
      if (Number(hour) > 72) return []
      return [hour, ':', minute, ':', seconds]
    },
    productName() {
      const { product_activity_type, product_name_language } = this.item || {}
      const suffix = this.language.SHEIN_KEY_PC_29428
      let nameEle = product_name_language || ''
      if (product_activity_type != 1) return nameEle
      if (this.GB_cssRight) {
        nameEle = `
          <span class='prime-card__name-suffix'>${suffix}</span>
          <span class='prime-card__name-divider'></span>
          <span>${nameEle}</span>
        `
      } else {
        nameEle = `
          <span>${nameEle}</span>
          <span class='prime-card__name-divider'></span>
          <span class='prime-card__name-suffix'>${suffix}</span>
        `
      }
      return nameEle
    }
  },
  mounted() {
    this.initTimer()
  },
  methods: {
    template,
    // 初始化倒计时
    initTimer () {
      let endTime
      const { product_price_info } = this.item || {}
      switch(this.activityType) {
        case ActivityType.Gift:
          endTime = this.primeGift[0].end_time
          break
        case ActivityType.Discount:
          if (product_price_info?.countdown) {
            endTime = this.item?.product_price_info.end_time
          }
          break
      }
      if (!endTime) return
      // 有部分数据返回的时间戳单位为秒
      if (endTime.toString().length < 13) endTime *= 1000
      const newDate = new Date().getTime()
      const countdown = endTime - newDate
      if (countdown <= 0) return
      this.timer = new CountDown()
      this.timer.start({
        seconds: Math.floor(countdown / 1000),
      })
    },
    getRightIcon(right) {
      // 『SHEIN中央：53840-付费会员积分发放,SHEIN欧洲：付费会员积分发放：53779-,SHEIN美国 ：52078-付费会员积分发放』
      // 积分
      if([53840, 53779, 52078].includes(+right?.right_type_id) || right?.right_type_code == 'MULTIPLE_POINTS') return 'sui_icon_extrapoints_club_fill_20px'
      // 免邮
      if (right?.right_type_id == 53) return 'sui_icon_shipping_club_fill_20px'
      // 折扣
      if (right?.right_type_id == 29) return 'sui_icon_specialprice_club_fill_20px'
      // 会员赠品
      if (right?.right_type_id == 4) return 'sui_icon_club_giftbox4_20px'
      return ''
    },
  },
  emits: ['handleActivityShow'],
}
</script>

<style lang="less" scoped>
.two-line-ellipsis() {
  overflow : hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.prime-card {
  width: 100%;
  height: 84px;
  background-image: url(/she_dist/images/checkout/prime_card_bg_new-8ecde42bfc.png);
  background-size: 100% 100%;
  border-radius: 9px;
  padding: 0 12px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transform-origin: left bottom;
    
  &_scale {
    scale: 1.15;
  }

  &_activity {
    height: 92px;
  }

  &:before {
    content: '';
    width: 94px;
    height: 74px;
    position: absolute;
    /*rtl:ignore*/
    left: 0;
    bottom: 0;
    background-image: url(/she_dist/images/checkout/prime_card_bg_logo-09fa378458.png);
    background-size: 100% 100%;
  }

  &__name {
    position: absolute;
    top: 0;
    .right(0);
    display: flex;
    height: 31px;
    align-items: center;
    font-size: 20px;
    transform-origin: right top;
    transform: scale(0.5);
    &_triangle {
      background-image: url(/she_dist/images/checkout/prime_name_bg_triangle-b94e978e39.png);
      background-size: 100% 100%;
      height: 100%;
      width: 24px;
      .margin-r(-4px);
    }
    &_bg {
      background-image: url(/she_dist/images/checkout/prime_name_bg-8cbc0769c4.png);
      background-size: 100% 100%;
      height: 100%;
      display: flex;
      align-items: center;
      .padding-r(12px);
      border-top-right-radius: 18px;

      > div {
        font-family: 'PingFang HK';
        font-weight: 600;
        color: rgba(135, 60, 0, .7);
        text-transform: capitalize;
        white-space: nowrap;
      }
      
    }

    // 自动续费产品包样式
    &_auto-renewal {
      /deep/ .prime-card {
        &__name_triangle {
          background-image: url(/she_dist/images/checkout/prime_header_bg_triangle_renewal_ar-ea52111a3f.png);
        }
        &__triangle_ar {
          background-image: url(/she_dist/images/checkout/prime_header_bg_triangle_renewal-983d05b929.png);
        }
        &__name_bg {
          background-image: none;
          background: #FFC7C3;
          color: #F17E53;
        }
        &__name-divider {
          display: inline-block;
          width: 2px;
          height: 18px;
          background-color: #F17E53;
          opacity: .4;
        }
        &__name-suffix {
          font-family: Arial;
          color: #FF5656;
          font-weight: 860;
        }
      }
    }
  }

  &__triangle_ar {
    background-image: url(/she_dist/images/checkout/prime_name_bg_triangle_ar-33b7e52502.png);
  }

  &__namebg_ar {
    background-image: url(/she_dist/images/checkout/prime_name_bg_ar-91b4997d42.png);
  }

  &__right {
    display: flex;
    justify-content: space-around;
    min-width: 182px;
    max-width: 189px;
    line-height: 1;

    &_activity {
      padding-bottom: 10px;
    }

    &_wrapper {
      flex: 1;
      height: 100%;
      z-index: 1;
      color: @sui_color_club_rosegold_dark1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    
    &_icon {
      font-size: 24px;
      line-height: 1;
    }

    &_des {
      max-width: 58px;
      margin: 0 3px;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.15;
      text-align: center;
      .two-line-ellipsis();
    }

    &_divider {
      z-index: 1;
      width: 1.5px;
      height: 18px;
      background: @sui_color_club_rosegold_dark1;
      opacity: .2;
      margin: 0 4px;
      align-self: center;

      &:last-child {
        display: none;
      }
    }
  }

  &__activity {
    background: linear-gradient(90.8deg, #E68A5C 28.67%, #E69F7C 50.13%, #E6895C 82.96%);
    height: 42px;
    width: 200%;
    position: absolute;
    bottom: 0;
    border-radius: 0 0 18px 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    scale: 0.5;
    transform-origin: center bottom;
    padding: 0 12px;

    &_left {
      display: flex;
      align-items: center;
    }

    &_icon {
      width: 24px;
    }

    &_tips {
      font-family: SF Pro;
      font-weight: 700;
      text-shadow: 0 1px 1px rgba(70, 0, 23, 0.4);
      font-size: 17.5px;
      color: #FFE9DD;
      line-height: 1;
      margin: 0 16px 0 6px;
      .two-line-ellipsis();
    }
  }

  &__count {
    font-size: 14px;
    display: flex;
    align-items: center;
    /*rtl:ignore*/
    direction: ltr; 
    &_item {
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: SF UI Text;
      font-weight: 700;
     
      &:nth-child(odd) {
        width: 22px;
        height: 18px;
        color: @sui_color_club_rosegold_dark2;
        background: rgba(255, 255, 255, 0.9);
        border-radius: 4px;
      }

      &:nth-child(even) {
        color: #fff;
        margin: 0 2px;
      }
    }
  }

  &_auto-renewal {
    &.prime-card_selected {
      border: 1.155px solid #FF5656;
    }
    .prime-card__activity {
      background: linear-gradient(180deg, #FF652B 0%, #FFA786 100%);
    }
  }

}

.prime-list__item_single {
  .prime-card__right {
    max-width: none;
    width: 90%;
  }
}
</style>
