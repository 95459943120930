<template>
  <div 
    class="checkout-activity-popover__tips"
  >
    <div
      class="checkout-activity-popover__tips-content"
    >
      <div
        ref="activityPopoverTipsDescRef"
        class="checkout-activity-popover__tips-des"
        :class="[!!timeTips ? 'two-rows-ellipsis' : 'three-rows-ellipsis']"
      >
        <Icon
          v-if="icon.name"
          class="checkout-activity-popover__tips-icon"
          :name="icon.name"
          :color="icon.color"
          :size="icon.size"
        />
        <span
          ref="descriptionRef"
          class="description"
          v-html="description"
        ></span>
      </div>
      <div 
        v-if="timeTips"
        class="checkout-activity-popover__tips-timedown"
      >
        {{ timeTips }}
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from '@shein-aidc/icon-vue3'

import { template, CountDown } from '@shein/common-function'

export default {
  name: 'ActivityTips',
  components: {
    Icon,
  },
  props: {
    language: {
      type: Object,
      default: () => {}
    },
    icon: {
      type: Object,
      default: () => {}
    },
    description: {
      type: String,
      default: ''
    },
    timedown: {
      type: [Number, String],
      default: 0
    },
  },
  data() {
    return {
      timer: null,
    }
  },
  computed: {
    // 倒计时文案
    timeTips () {
      if (!this.timer) return ''
      const { H, M, S } = this.timer?.timeObj || {}
      return this.template(
        `${H}:${M}:${S}`,
        this.language.SHEIN_KEY_PC_29116
      )
    },
    isClient() {
      return typeof window !== 'undefined'
    }
  },
  watch: {
    timedown: {
      handler(newVal, oldVal) {
        this.initTimer()
        if (newVal !== oldVal) {
          this.handleDescriptionFontSize()
        }
      },
      immediate: true
    },
    description: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.handleDescriptionFontSize()
        }
      }
    }
  },
  methods: {
    template,
    initTimer () {
      let endTime = +this.timedown
      if (!endTime) return
      if (endTime.toString().length < 13) endTime *= 1000
      const newDate = new Date().getTime()
      const countdown = endTime - newDate
      if (countdown <= 0) return
      this.timer = new CountDown()
      this.timer.start({
        seconds: Math.floor(countdown / 1000),
      })
    },
    /**
     * @description: 用于处理descript的字号
     * @return {*}
     */    
    handleDescriptionFontSize() {
      if (!this.isClient) {
        return
      }
    
      this.$nextTick(() => {
        setTimeout(()=>{
          const maxHeight = 27
          const descriptionHeight = this.$refs.activityPopoverTipsDescRef?.offsetHeight || 0

          if(descriptionHeight > maxHeight) {
            this.$refs.descriptionRef.style.fontSize = '10px'
          } else {
            this.$refs.descriptionRef.style.fontSize = '12px'
          }
        }, 0)
      })
    }
  }
}
</script>

<style lang="less">
.checkout-activity-popover__tips {
  .margin-l(8px);
}
.checkout-activity-popover__tips-icon {
  .margin-r(2px);
}
.checkout-activity-popover__tips-content {
  line-height: 1;
}
.checkout-activity-popover__tips-des {
  .description {
    color: @sui_color_white;

    em {
      font-weight: 700;
      color: @sui_color_discount;
    }

    .prime {
      color: @sui_color_club_rosegold_dark3;
    }
    .flash {
      color: @sui_color_flash;
    }
  }
}
.two-rows-ellipsis {
  overflow: hidden;
  white-space: normal;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.three-rows-ellipsis {
  overflow: hidden;
  white-space: normal;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
.checkout-activity-popover__tips-timedown {
  font-size: 10px;
  color: @sui_color_guide;
  margin-top: 2px;
}
</style>
