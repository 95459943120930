import isEqual from 'lodash/isEqual'
import { prefetchResource } from 'public/src/services/prefetchResource'
import { abtservice } from 'public/src/services/abt'
import { windowCacheDeleteCb } from '@shein/common-function'
import { useTogether } from 'public/src/pages/checkout/hooks/useTogether.js'
import { isLogin } from 'public/src/pages/common/utils/index.js'
import schttp from 'public/src/services/schttp'

// 默认轮询时间间隔
const TIME_LOCALHOST = 20000

/**
 * html资源缓存通用类
 * 需要调用this函数一定使用箭头函数，其他无所谓
 * {instance} {HtmlCacheClass} 存储单例
 * {isClient} {boolean} 判断是浏览器客户端环境而不是SSR的node环境且支持fetch
 * {sessionStorageKey} {string} 判断是否需要单例请求存储在sessionStorage中的key名
 * {loopProps} { source: string } 轮询属性
 * {timeMachine} {number} 轮询定时器
 * {abtCanContinue} {boolean} abt开关，注意resetFn还原
 * {abtReqSuccess} {boolean} abt接口是否已请求成功，用来做请求单例，注意resetFn还原
 * {apolloConfigKey} {string} apollo开关名称
 * {removeKeyName} {string} sw中缓存的key
 * {blackUrlPathAry} {[]string} 设置url的path，在这些url中则不发起请求。毕竟有的页面需要注册HtmlCacheClass仅调用清空缓存方法，不应该发起轮询
 * {hasAddPrefetchResourcesListen} {boolean} 是否已经执行prefetchResources的listen事件
 */
export class HtmlCacheClass {
  instance
  isClient = false
  loginStatus = false
  checkoutPrefetchStorageKey = 'checkoutPrefetchStorageKey'
  sessionStorageKey = 'pcCheckoutHtmlCompareData'
  loopProps = {}
  timeMachine = null
  abtCanContinue = false
  abtReqSuccess = false
  apolloConfigKey = 'CHECKOUT_PERLOAD_SWITCH'
  removeKeyName = ['pcCheckoutHtml', 'pcCheckoutRecommendProducts']
  blackUrlPathAry = ['/checkout']
  hasAddPrefetchResourcesListen = false
  constructor () {
    this.initFn()
  }
  static getInstance = () => {
    try {
      if (typeof window !== 'undefined' && window?.HtmlCacheClassInstance instanceof HtmlCacheClass) {
        this.instance = window.HtmlCacheClassInstance
      } else if (typeof window !== 'undefined' && !this.instance) {
        this.instance = window.HtmlCacheClassInstance = new HtmlCacheClass()
      } else if (!this.instance) {
        this.instance = new HtmlCacheClass()
      }
    } catch (e) {
      console.error('HtmlCacheClass getInstance Error', e) 
      this.instance = new HtmlCacheClass()
    } finally {
      return this.instance
    }
  }
  initFn = () => {
    try {
      if (typeof window !== 'undefined' && typeof window?.fetch === 'function') {
        this.isClient = true
        this.loginStatus = typeof isLogin === 'function' && isLogin()
        window.addEventListener('visibilitychange', () => { 
          if (document?.visibilityState === 'visible') {
            // 回到页面，定时器重新开启，参数为上次保存内容
            this.loopFn(this.loopProps)
          } else {
            // 离开页面，定时器停止
            clearTimeout(this.timeMachine)
          }
        })
        window.addEventListener('beforeunload', () => {
          // 离开页面或者刷新时清空，防止使用者遗漏清空
          this.resetFn()
        })
      } else {
        this.isClient = false
      }
    } catch (e) {
      this._getErrorMsg('initFn', e)
    } finally {
      return this
    }
  }
  _getABTInfo = async () => {
    let res = false
    try {
      // abt请求未成功，需要发起请求
      if (!this.abtReqSuccess) {
        // eslint-disable-next-line @shein-aidc/abt/abt
        const abtResult = await abtservice.getUserAbtResult({ newPosKeys: 'CheckoutHtml' })
        // 接口请求成功
        this.abtReqSuccess = true
        // abt返回正常，以abt结果为准
        res = this.abtCanContinue = abtResult?.CheckoutHtml?.param?.CheckoutHtmlStatus === '1'
      } else {
        // abt异步请求已成功过，可以使用存储的值
        res = this.abtCanContinue
      }
    } catch (e) {
      // 发生异常，默认阻塞后续逻辑
      res = this.abtReqSuccess = this.abtCanContinue = false
      this._getErrorMsg('_getABTInfo', e)
    } finally {
      return res
    }
  }
  /**
   * 发送异步请求，更新HTML资源
   * @param {source} string 资源路径 
   */
  sendHTMLFn = (props) => {
    try {
      if (!this.isClient) { return this }
      const { source } = props ?? {}
      typeof source === 'string' && window.fetch(source, { 
        headers: { 
          prefetch: '1' 
        } 
      })
    } catch (e) {
      this._getErrorMsg('sendHTMLFn', e)
    } finally {
      return this
    }
  }
  /**
   * 发送异步请求，更新接口资源
   */
  sendReqFn = async (props) => {
    try {
      if (!this.isClient) { return this }
      const { carts } = props ?? {}
      Array.isArray(carts) && carts?.length > 0 && schttp({
        method: 'POST',
        url: '/api/checkout/recommendProducts/get?_init_enter=1',
        data: {
          // pageNum和limit顺序还不能错
          pageNum: 1,
          limit: 25,
          ...(await useTogether({ carts }))
        }
      })
    } catch (e) {
      this._getErrorMsg('sendReqFn', e)
    } finally {
      return this
    }
  }
  prefetchReqFn = async () => {
    try {
      if (!this.isClient) { return this }
      const { checkoutJs, code } = (await schttp({
        method: 'GET',
        url: '/api/checkout/getCheckoutResource',
      }))
      const hasCheckoutJs = code === 0 && Array.isArray(checkoutJs) && checkoutJs?.length > 0
      const storageKey = this.checkoutPrefetchStorageKey
      const storageContent = window?.localStorage?.getItem(storageKey)
      if (hasCheckoutJs && (!storageContent || !isEqual(JSON.parse(storageContent), checkoutJs))) {
        console.log('HtmlCacheClass prefetchReqFn 创建link')
        window.localStorage.setItem(storageKey, JSON.stringify(checkoutJs))
        checkoutJs.forEach((resource) => {
          const link = document.createElement('link')
          link.setAttribute('rel', 'prefetch')
          link.setAttribute('href', resource)
          if (resource.includes('.png') || resource.includes('.jpg')) {
            link.setAttribute('as', 'image')
          } else if (resource.includes('.js')) {
            link.setAttribute('as', 'script')
          } else if (resource.includes('.css')) {
            link.setAttribute('as', 'style')
          }
          document.head.appendChild(link)
        })
      } else {
        console.log('HtmlCacheClass prefetchReqFn 不创建link')
      }
    } catch (e) {
      this._getErrorMsg('prefetchReqFn', e)
    } finally {
      return this
    }
  }
  /**
   * 类似于React.useEffect
   * @param {Function} callbackFn 回调函数
   * @param {[]any} effects 依赖数组
   * @param {number} timeLimit 自定义间隔时间（ms），超过该时间触发该方法立即开启轮询
   * @returns 
   */
  loopFnEffects = (callbackFn, effects, timeLimit = TIME_LOCALHOST) => {
    try {
      if (!this.isClient) { return this }
      const timestamp = Date.now()
      const storageContent = JSON.parse(window?.sessionStorage?.getItem(this.sessionStorageKey)) 
      const storageTimestamp = storageContent?.timestamp
      const storageEffects = storageContent?.effects
      // 如果有新的参数 && (如果值发生改变 || 再次触发时超过轮询时间)
      if (effects && (!isEqual(effects, storageEffects) || Math.abs(timestamp - storageTimestamp) > timeLimit)) {
        // console.log('===== HtmlCacheClass loopFnEffects 发起预缓存 =====')
        typeof callbackFn === 'function' && callbackFn()
        this.sessionStorageKey && window?.sessionStorage?.setItem(this.sessionStorageKey, JSON.stringify({
          effects,
          timestamp
        }))
      } else {
        // console.log('===== HtmlCacheClass loopFnEffects 预缓存无需改变，不发起请求 =====')
      }
    } catch (e) {
      this._getErrorMsg('loopFnEffects', e)
    } finally {
      return this
    }
  }
  prefetchResources() {
    try {
      if (!this.isClient) { return this }
      if (this.blackUrlPathAry.includes(window?.location?.pathname)) { return this }
      if (this.hasAddPrefetchResourcesListen) { return this }
      // el 必须是渲染完的
      prefetchResource.listen({
        el: document.querySelector('.j-shopbag-body'),
        prefetchList: [
          {
            chunkName: 'checkout',
            relType: 'prefetch'
          }
        ],
        delay: 500
      })
      hasAddPrefetchResourcesListen = true
    } catch (e) {
      hasAddPrefetchResourcesListen = false
      this._getErrorMsg('prefetchResources', e) 
    } finally {
      return this
    } 
  }
  /**
   * 开启/重置轮询任务，更新资源
   * @param {source} string 普通资源路径 
   * @param {carts} array/[Proxy]array 购物车列表数据
   * @param {prefetchFlag} boolean 是否发起预请求外部控制开关
   */
  loopFn = (loopProps) => {
    try {
      // 不是客户端直接取消
      if (!this.isClient) { return this }
      // 最好不要和上个条件合并，此条件必须是在客户端条件下才需要判断
      // 目前只有visiblechange会触发，但是为了避免后续有新类型触发，判断统一写在方法内部
      if (this.blackUrlPathAry.includes(window?.location?.pathname)) { return this }
      // 清除缓存，自动轮询不清除sessionStorage，一般外部手动调用才清空
      this.clearCacheFn({ clearSessionStorage: false })
        .then(() => {
          // prefetchFlag不用为true判断，未传入也默认为通过（不为false即通过）
          if (
            window?.gbCommonInfo?.[this.apolloConfigKey] !== 'OFF' &&
            this.loginStatus &&
            loopProps?.prefetchFlag !== false
          ) {
            // 首先清空上次定时器，可同时达成以下两点诉求
            // 如果是定时器调用，防止内存溢出
            // 如果是手动调用，可以重置定时器
            clearTimeout(this.timeMachine)
            // 保存参参数，props优先使用之前定义过的，不过外面还是尽量传
            // 如果定时器重新开启会使用this.loopProps发起轮询
            this.loopProps = loopProps ?? this.loopProps
            // console.log('===== HtmlCacheClass 预缓存执行 =====', this, this?.loopProps?.source, Date.now())
            this.sendHTMLFn(this.loopProps)
            this.sendReqFn(this.loopProps)
            this.prefetchReqFn()
            this.prefetchResources()
            // 轮询时间，同PWA
            this.timeMachine = setTimeout(() => {
              this.loopFn(this.loopProps)
            }, TIME_LOCALHOST)
          } else {
            console.log('===== HtmlCacheClass 不符合可预缓存条件 =====', window?.gbCommonInfo?.[this.apolloConfigKey], this.loginStatus, loopProps?.prefetchFlag)
          }
        })
    } catch (e) {
      this._getErrorMsg('loopFn', e)
    } finally {
      return this
    }
  }
  /**
   * 只是清除一些内部属性
   * @returns 
   */
  resetFn = () => {
    try {
      if (!this.isClient) { return this }
      clearTimeout(this.timeMachine)
      this.loopProps = {}
      this.abtCanContinue = false
      this.abtReqSuccess = false
    } catch (e) {
      this._getErrorMsg('resetFn', e)
    } finally {
      return this
    }
  }
  /**
   * 清除客户端数据，需要选择性单独调用
   * @param {string} removeKeyName 
   * @returns 
   */
  clearCacheFn = async (props) => {
    try {
      if (!this.isClient) { return this }
      const { removeKeyName, clearSessionStorage = true } = props ?? {}
      typeof this.sessionStorageKey === 'string' && clearSessionStorage && window.sessionStorage.removeItem(this.sessionStorageKey)
      const removeKey = removeKeyName ?? this.removeKeyName
      if (typeof removeKey === 'string') {
        await windowCacheDeleteCb({ key: removeKey })
      }
      if (Array.isArray(removeKey)) {
        await windowCacheDeleteCb({ keys: removeKey }) 
      }
    } catch (e) {
      this._getErrorMsg('clearCacheFn', e)
    } finally {
      return this
    }
  }
  /**
   * 报错字符串
   * @param {name} string 方法名，不使用function.name是因为编译后方法名不可控
   * @param {e} Error error对象
   * @returns string
   */
  _getErrorMsg = (name, e) => {
    let resStr = `[HtmlCacheClass] [${name}] Error`
    if (this.isClient) {
      resStr += `: ${window?.location?.href}`
    }
    console.error(`%c${resStr}`, 'color: #ff0000; font-weight: bold', e)
  }
}
