<template>
  <div :class="['header-contianer', !isMultipleProducts && 'singleRefresh']">
    <div
      v-if="isMultipleProducts"
      class="left"
    >
      <span class="des">{{ language.SHEIN_KEY_PC_28075 }}</span>
      <div
        class="change"
        @click="changeData"
      >
        <Icon 
          ref="changeIcon" 
          name="sui_icon_change_16px_1" 
          size="16px"
          color="#2D68A8"
        />
        <span class="text">{{ language.SHEIN_KEY_PC_28076 }}</span>
      </div>
    </div>
    
    <div
      v-else
      class="singleRefresh-left"
    >
      <span
        class="buyHand"
        v-html="cheaperText"
      ></span>
      <div
        class="change"
        @click="changeData"
      >
        <Icon 
          ref="changeIcon" 
          name="sui_icon_change_16px_1" 
          size="12px" 
          color="#BBB"
        />
        <span class="text">{{ language.SHEIN_KEY_PC_28076 }}</span>
      </div>
    </div>

    <Icon 
      name="sui_icon_closed_filter_12px_1" 
      size="16px" 
      color="#959595" 
      class="close"
      @click="close"
    />
  </div>
</template>
<script>
import { Icon } from '@shein-aidc/icon-vue3'
import { mapState } from 'vuex'
import gsap from 'gsap'

export default {
  components: {
    Icon
  },
  props: {
    togetherBuy: {
      type: Object,
      default: () => {}
    },
    isMultipleProducts: {
      type: Boolean,
      default: false
    }
  },
  emits: ['changeEmit', 'closeEmit'],
  data(){
    return {
      iconDynamic: null,
    }
  },
  computed: {
    ...mapState(['checkout', 'language']),
    cheaperText(){
      const { SHEIN_KEY_PC_29416, SHEIN_KEY_PC_29456 } = this?.language
      let regex = new RegExp('(' + SHEIN_KEY_PC_29456 + ')', 'g')
      let replacedText = SHEIN_KEY_PC_29416.replace(regex, '<span class="Cheaper">$1</span>')
      return replacedText
    }
  },
  methods: {
    changeData(){
      this.changeDataDynamic()
      this.$emit('changeEmit')
    },
    changeDataDynamic() {
      if (!this.iconDynamic) {
        this.iconDynamic = gsap.to(this.$refs.changeIcon.$el, {
          duration: 0.7,
          rotation: 360,
          ease: 'ease.both'
        })
      } else {
        this.iconDynamic.restart()
      }
    },
    close(){
      this.$emit('closeEmit', false)    
    }
  }
}
</script>
<style lang="less" scoped>
.header-contianer{
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  .left{
    display: flex;
    .des{
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      color: #000000;
      margin-right: 4px;
    }
    .change{
      display: flex;
      padding: 1px 6px 1px 4px;
      align-items: center;
      border-radius: 16px;
      border: 1px solid #2D68A8;
      background: #FFF;
      box-sizing: border-box;
      font-size: 11px;
      color: #2D68A8;
      cursor: pointer;
      height: 20px;
      max-width: 118px;
      .text{
        line-height: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .close{
    cursor: pointer;
    margin-left: 6px;
  }
}
.singleRefresh{
  background: linear-gradient(180deg, rgba(255, 133, 133, 0.20) 0%, rgba(255, 121, 121, 0.00) 100%), #FFF;
  display: flex;
  // padding: 12px 8px 0 8px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  padding: 12px 12px 0;
  .singleRefresh-left{
    display: flex;
    width: calc(100% - 22px);
    .buyHand{
      color: var(---sui_color_brand, #000);
      font-family: "Arial Black";
      font-size: 20px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      // display: flex;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      /deep/ span{
        background-image: linear-gradient(90deg, #FF2A0D 4.08%, #FA8938 104.08%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        color: transparent;
        font-family: "Arial Black";
        font-size: 20px;
        font-style: normal;
        font-weight: 900;
        line-height: 24px; /* 120% */
        // margin-right: 8px;
      }
    }
    .change{
      display: flex;
      border-radius: 16px;
      border: 1px solid #BBB;
      cursor: pointer;
      align-self: center;
      margin-left: 6px;
      margin-top: 3px;
      padding: 2px 4px;
      min-width: 64px;
      height: 17px;
      color: #666666;
      text-align: right;
      font-family: "SF Pro";
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      .text{
        line-height: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-left: 4px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 200px;
      }
    }
  }
}
</style>
