<template>
  <div class="shipping-address-new-card fsp-element">
    <div
      class="shipping-address-new-card__header"
      tabindex="0"
    >
      <h4 class="shipping-address-new-card__header-title">
        {{ title || language.SHEIN_KEY_PC_14720 }}
      </h4>
    </div>
    <div 
      v-if="checkout.default_address"
      class="shipping-address-new-card__body"
    >
      <div class="shipping-address-info">
        <div
          class="shipping-address-info__header"
          tabindex="0"
        >
          <div class="shipping-address-info__header-left-wrapper">
            <span class="shipping-address-info__name">{{ sortAddressCardInfo({ detail: checkout.default_address }) }}</span>
            <span class="shipping-address-info__telphone">{{ checkout.default_address.tel }}</span>
          </div>
          <div 
            class="shipping-address-info__header-right-wrapper"
            @click="clickEditAddress(-1, checkout.default_address)"
          >
            <i class="sh_pc_sui_icon_edit_accounts_18px"></i>
          </div>
        </div>
        <div
          v-if="checkout.default_address.countryId == '108'"
          class="shipping-address-info__detailed-address"
          tabindex="0"
        >
          〒 {{ checkout.default_address.postcode }}
        </div>
        <div
          v-else 
          class="shipping-address-info__detailed-address"
          tabindex="0"
        >
          {{ checkout.default_address.address1 }}
          {{ checkout.default_address.address2 }}
          <template v-if=" checkout.default_address.countryId == '209' && checkout.default_address.type == '3'">
            ({{ language.SHEIN_KEY_PC_17772 }})
          </template>
        </div>
        <div
          v-if="checkout.default_address.countryId == '108'"
          class="shipping-address-info__other-address"
          tabindex="0"
        >
          <div>
            {{ checkout.default_address.countryName }}
            {{ checkout.default_address.state }}
            {{ checkout.default_address.city }}
            {{ checkout.default_address.district }}
            {{ checkout.default_address.street }}
            {{ checkout.default_address.address1 }} {{ checkout.default_address.address2 }}
            {{ checkout.default_address.countryName }}
          </div>
          <div v-if="checkout.default_address.isDefault == 1">
            <s-label
              class="shipping-address-info__tag"
              type="success"
            >
              {{ language.SHEIN_KEY_PC_14782 }}
            </s-label>
          </div>
        </div>
        <div
          v-else 
          class="shipping-address-info__other-address"
          tabindex="0"
        >
          <div>
            {{ checkout.default_address.street }}
            {{
              [checkout.default_address.district, secondLanguage.district]
                .filter(Boolean)
                .join('/')
            }}
            {{
              [checkout.default_address.city, secondLanguage.city]
                .filter(Boolean)
                .join('/')
            }}
            {{
              [checkout.default_address.state, secondLanguage.state]
                .filter(Boolean)
                .join('/')
            }}
            {{ checkout.default_address.countryName }}
            {{ checkout.default_address.postcode }}
          </div>
          <div v-if="checkout.default_address.isDefault == 1">
            <s-label
              class="shipping-address-info__tag"
              type="success"
            >
              {{ language.SHEIN_KEY_PC_14782 }}
            </s-label>
          </div>
        </div>
        <!-- 地址错误提示 -->
        <div
          v-if="(checkout.default_address.countryId == '178' && checkout.default_address.fatherName == '') || checkout.default_address.editHint == 1"
          class="shipping-address-info__error-tips"
          tabindex="0"
        >
          {{ language.SHEIN_KEY_PC_14804 }}
        </div>
        <div
          v-if="sensitiveNote"
          class="shipping-address-info__error-tips"
          tabindex="0"
        >
          {{ sensitiveNote }}
        </div>
      </div>

      <ClientOnly>
        <address-title-tips
          v-if="status.adrTitleTipsShow"
          :language="language"
          :adr-name-verified-title-tip="adrNameVerifiedTitleTip"
          :status="status"
          :checkout="checkout"
          :kr-copywrite-tip="krCopywriteTip"
          @click-tw-jump-url="clickTwJumpUrl"
          @click-address-tips="clickAddressTips"
        />
      </ClientOnly>
    </div>
    <div class="shipping-address-new-card__footer">
      <div
        v-if="isShowChangeBtn"
        class="footer-btn-group"
        @click="chooseAddressHandler"
      >
        <span class="footer-btn-group__btn-text">{{ language.SHEIN_KEY_PC_25884 }}</span>
      </div>
      <div
        class="footer-btn-group"
        @click="clickAddAddress"
      >
        <span class="footer-btn-group__btn-text">+{{ language.SHEIN_KEY_PC_14754 }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

import AddressTitleTips from './AddressTitleTips.vue'

import { sortAddressCardInfo } from 'public/src/pages/components/address/config'
import { daEventCenter } from 'public/src/services/eventCenter/index'

daEventCenter.addSubscriber({ modulecode: '1-11-1' })

export default {
  name: 'ShippingAddressCard',
  emits: [
    'click-tw-jump-url',
    'click-address-tips',
    'click-change-btn'
  ],
  components: {
    AddressTitleTips,
  },
  props: {
    adrNameVerifiedTitleTip: {
      type: String,
      default: ''
    },
    status: {
      type: Object,
      default: () => {}
    },
    checkout: {
      type: Object,
      default: () => {}
    },
    secondLanguage: {
      type: Object,
      default: () => {}
    },
    krCopywriteTip: {
      type: String,
      default: ''
    },
    sourceGray: {
      type: Array,
      default: () => []
    },
    language: {
      type: Object,
      default: () => {}
    },
    title: {
      type: String,
      default: ''
    },
    // 是否展示change按钮
    isShowChangeBtn: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    sensitiveNote () {
      const sensitiveRuleVoList = this.checkout?.default_address?.sensitiveRuleVoList || []
      if (!sensitiveRuleVoList.length) {
        return ''
      }
      return sensitiveRuleVoList?.[0]?.rule_content || ''
    }
  },
  methods: {
    ...mapMutations([
      'assignState', 
    ]),
    sortAddressCardInfo,
    clickTwJumpUrl(event) {
      this.$emit('click-tw-jump-url', event)
    },
    clickAddressTips(event) {
      this.$emit('click-address-tips', event)
    },
    chooseAddressHandler() {
      this.assignState({
        showAddressDialog: true
      })
      daEventCenter.triggerNotice({
        daId: '1-11-1-129',
      })
      this.$emit('click-change-btn')
    },
    clickEditAddress(index, address) {
      window.checkoutEventCenter.emit('click-edit-address', { index, address })
    },
    clickAddAddress() {
      window.checkoutEventCenter.emit('click-add-address')
    },
  }
}
</script>

<style lang="less">
.shipping-address-new-card {
  padding: 16px;
  color: @sui_color_gray_dark1;
  background: #ffffff;
  font-family: Arial;


  .address-title-tips-screen-efficiency {
    margin-top: 16px;
    font-size: 12px;
    line-height: 14px;
    padding: 10px;
    color: #666;
    background: #f6f6f6;

    a {
      color: #1860a7;
    }
  }

  &__header {
    margin-bottom: 16px;
  }

  &__header-title{
    font-size: 20px;
    font-weight: 900;
    font-family: Arial Black;
    text-transform: capitalize;

    & when (@IS_RW) {
      font-family: Adieu;
    }
  }

  &__body {
    padding: 20px 12px;
    border: 1px solid #e5e5e5;
    border-left: none;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 4px;
      height: 100%;
      background-image: url('/she_dist/images/border-e8fd9a0d48.png');
    }
  }

  .shipping-address-info {

    &__header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
    }

    &__header-left-wrapper {
      display: flex;
      flex-wrap: wrap;
      row-gap: 8px;
      margin-right: 16px;
    }

    &__name {
      margin-right: 16px;
      font-size: 16px;
      font-weight: 700;
      text-transform: capitalize;
    }

    &__telphone {
      color: #959595;
      font-size: 16px;
      white-space: nowrap;
    }

    &__header-right-wrapper {
      display: flex;
      cursor: pointer;
    }

    .sh_pc_sui_icon_edit_accounts_18px {
      display: inline-block;
      background: url(data:image/svg+xml;base64,PHN2ZyBzdHlsZT0id2lkdGg6IGF1dG87IGhlaWdodDogMWVtOyIgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIiB2aWV3Qm94PSIwIDAgMjAgMjAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMTcuNjA1OSA2LjA4OTg0TDE0LjgyOTEgMy4yMTA2OUwxMy4zODk1IDQuNTk5MDhMMTYuMTY2MyA3LjQ3ODIzTDE3LjYwNTkgNi4wODk4NFpNMTUuMzAxNiA4LjMxMjE3TDEyLjUyNDggNS40MzMwMkw0Ljc0NzQxIDEyLjkzMzlWMTUuODEzMUg0Ljc0OTUxVjE1Ljg4NzlIMTYuMjAyOVYxNC44ODc5SDguNDgzNDNMMTUuMzAxNiA4LjMxMjE3WiIgZmlsbD0iYmxhY2siPgo8L3BhdGg+Cjwvc3ZnPg==) no-repeat;
      background-size: 100%;
      width: 18px;
      height: 18px;
    }

    &__detailed-address {
      margin-bottom: 8px;
      color: #000;
      font-size: 14px;
      font-weight: 400;
    }

    &__other-address {
      color: #000;
      font-size: 14px;
    }

    &__tag {
      margin-top: 16px;
    }

    &__error-tips {
      margin-top: 16px;
      font-size: 13px;
      line-height: 15px;
      color: #bf4123;
    }
  }

  &__footer {
    margin-top: 20px;
  }

  .footer-btn-group {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    padding: 9px 16px;
    margin-bottom: 16px;
    border: 1px solid #222;
    background: #ffffff;
    cursor: pointer;
    
    &__btn-text {
      color: #222;
      text-align: center;
      font-size: 16px;
      font-weight: 700;
    }
    
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
