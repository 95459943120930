import { daEventCenter } from 'public/src/services/eventCenter'
daEventCenter.addSubscriber({ modulecode: '1-11-3' })


export const goodsItem_config = {
  oldCard: {
    // 点击商品 快速查看
    isQuickView: true,
    isModule: true,
    // 是否展示促销
    showPromotion: true,
    hideGoodsName: true,
    // 是否展示降价提醒
    showReducePrice: true,
    showNewFlashPrice: true,
    showSheinClubDiscountValue: true,
    showQuickShip: true,
    useOwnClickAddBagAnalysis: true
  },
  newCard: {
    common: {
      isQuickView: true, // 点击商品 快速查看
      showPriceDiscountDesc: true, // 历史低价标签 => 价格优势说明标签
      showEstimatedPrice: false,
      salesLabel: true, // 销量标签
      showDiscountLabelAfterPrice: false,
      // showAddBagBtn: true, // 加车按钮
      showTitle: true, // 展示标题
      locateLabels: true,
      showEstimateTag: false,
      showEstimatedPriceOnSale: false,
      showEstimatedPriceReachThreshold: false,
      noNeedAnyEstimatedPriceTag: true
    }
  },
  SingleImageRowCardConfig: {
    // 折扣显示名称前
    showDiscountLabelBeforeGoodsName: true,
    showDiscountLabelBeforeGoodsNameHighPriority: true,
    
    showEstimateTag: false,
    // 展示原价划线价
    showRetailPrice: true,
    // 到手价
    showEstimatedPriceOnSale: false,
    noNeedAnyEstimatedPriceTag: true,
    showTitle: true,
    showAddBagBtn: true,
    
    // 快速发货
    showQuickShip: true,
    quickShip: true,
    // 点击快速查看
    isQuickView: true,
    // 用户行为标签
    showUserBehaviorLabel: true,
    showSellingPoint: true,
    // 角标 优质卖家
    showBadge: true,
    style: {
      addBagBtnStyle: 'AddText'
    },
    from: 'add_more_items'
  }
}

export const daEventCenterMap = {
  // 随单购商品点击
  'click-together': (goods_list, params) => {
    daEventCenter.triggerNotice({ 
      daId: '1-11-3-2',
      extraData: {
        goods_list,
        ...params
      }
    })
  },
  // 加车结果后埋点
  'report-analysis-data': (params)=>{
    daEventCenter.triggerNotice({ 
      daId: '1-11-3-3',
      extraData: {
        ...params,
      }
    })
  },
  // 加车成功后埋点
  'report-success-data': ({ beforeInfo, afterInfo }) => {
    daEventCenter.triggerNotice({ 
      daId: '1-11-3-4',
      extraData: {
        before_info: beforeInfo,
        after_info: afterInfo
      }
    })
  },
  // 埋点曝光
  'together-module_list': (params) => {
    daEventCenter.triggerNotice({ 
      daId: '1-11-3-1',
      extraData: {
        style: 'popup',
        goods_list: '',
        ...params
      }
    })
  },
}
