<template>
  <div class="autoPaymenodsGuide_container">
    <div
      v-show="finishVisible"
      :class="['bubble-wrapper', `${options.guideType}_wrapper-active`]"
    >
      <Icon
        class="bubble-wrapper__close-btn"
        name="sui_icon_closed_filter_12px_1"
        size="10px"
        color="#fff"
        @click.stop="handleBubbleCloseBtnClick"
      />
      <renewalPayMethods
        :banks-config="banksConfig"
        :paypal-ga-vault="paymentMethodsProps.paypalGaVault"
        :payment-methods-props="paymentMethodsProps"
        :status="status"
        auto-type="xtra"
        @handle-bubble-click="handlePayMethodsListDrawerShow"
        @filter-payment-finish="fiterPaymentFinish"
      />

      <PayMethodsListDialog 
        ref="PayMethodsListDialogRef" 
        :checkout="checkout"
        :language="language" 
        :payment-methods-props="paymentMethodsProps"
        :pay-methods-list-drawer-header-data="payMethodsListDrawerHeaderData"
        auto-type="xtra"
        :auto-payment-submit-emit="autoPaymentSubmitEmit"
      />
    </div>
  </div>
</template>
<script setup>
import { ref, computed } from 'vue'
import { Icon } from '@shein-aidc/icon-vue3'
import renewalPayMethods from 'public/src/pages/checkout/components/summary/renewal_pay_methods.vue'
import PayMethodsListDialog from 'public/src/pages/checkout/components/payment_list_dialog/pay_methods_list_dialog.vue'
import { useStore } from 'vuex'

const props = defineProps({
  paypalGaVault: {
    type: Object,
    default: () => {}
  },
  banksConfig: {
    type: Object,
    default: () => {}
  },
  paymentMethodsProps: {
    type: Object,
    default: () => {}
  },
  status: {
    type: Object,
    default: () => {}
  },
  options: {
    type: Object,
    default: () => ({
      guideType: 'bubble', // 气泡展示还是正常显示 bubble/normal
      iconShow: true
    })
  },
  autoSubmit: {
    type: Function,
    default: () => {
      console.log('autoSubmit Function default')
    }
  },
  productCode: {
    type: String,
    default: ''
  }
})
const emits = defineEmits(['bubbleClose'])
const store = useStore()
const PayMethodsListDialogRef = ref(null)
const payMethodsListDrawerHeaderData = ref({})
const finishVisible = ref(false) // 解决renewalPayMethods组件异步获取数据导致样式没加载数据问题

const checkout = computed(() => {
  return store.state.checkout
})
const language = computed(() => {
  return store.state.language
})
const xtraAutoController = computed(() => {
  return store.getters.xtraAutoController
})

const handleBubbleCloseBtnClick = () => {
  emits('bubbleClose', false)
}
const handlePayMethodsListDrawerShow = () => {
  const productInfo = xtraAutoController.value.xtraProductAuto.find(f => f.product_code == props.productCode) || xtraAutoController.value.xtraProductAuto[0] || {}
  const { product_name_language, product_price_info, product_code } = productInfo || {}
  const {
    total_discount_price_with_symbol,
    arrival_price_with_symbol,
    price_local_with_symbol
  } = product_price_info || {}
  payMethodsListDrawerHeaderData.value = {
    product_name_language,
    total_discount_price_with_symbol,
    arrival_price_with_symbol,
    price_local_with_symbol,
    product_code
  }
  PayMethodsListDialogRef.value?.handlerShow()
}

const autoPaymentSubmitEmit = () => {
  window?.checkoutEventCenter && window?.checkoutEventCenter.emit('xtra-auto-payment-method-submit',  { productCode: props.productCode, DwVisible: props.options.guideType == 'normal' })
}

const fiterPaymentFinish = (val) => {
  finishVisible.value = !!val?.length
}

defineExpose({
  handlePayMethodsListDrawerShow
})

</script>
<style lang="less" scoped>
  .bubble-wrapper {
    padding: 8px;
    border-radius: 2px;
    box-sizing: border-box;
    background: var(---sui_color_black_alpha80, rgba(0, 0, 0, 0.80));
    color: #FFF;
    font-size: 12px;
    max-width: 270px;
    width: 220px;
    &__close-btn {
      position: absolute;
      top: 0;
      right: 0;;
    }
  }
  .bubble_wrapper-active{
    position: relative;
    left: -1px;
    top: -1rem;
    border-radius: 2px;

    &::before {
      position: absolute;
      bottom: -5px;
      left: 7px;
      content: '';
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid rgba(0, 0, 0, 0.80);
    }
  }
  .normal_wrapper-active{
    // position: absolute;
    // top: 0;
    // left: 0;
    width: 100%;
    margin-bottom: 3px;
    max-width: 100%;
  }
  
</style>
