<template>
  <div
    class="point-tip"
    tabindex="0"
  >
    <template v-if="caculateInfo.prime_points">
      <Icon
        v-if="IS_RW"
        name="sui_icon_club_logo_fill_16px"
        size="16px"
        color="#C96E3F"
      />
      {{ language.SHEIN_KEY_PC_14748 }}
      <span>
        <span class="point-prime">{{ caculateInfo.totalPoints }}</span>
        <span class="point-prime">({{ `${caculateInfo.rewardPoints * 1 + caculateInfo.getDoublePoints * 1}` }} +</span>
        <span class="point-prime"><prime-vip-icon
          v-if="!IS_RW"
          top="2"
          class="prime-vip-icon-center"
        />{{ caculateInfo.prime_points }})</span>
        <s-popover 
          placemen="bottom-end" 
          trigger="hover"
          :prop-style="{width: '266px', boxShadow: '0px 2px 10px 2px rgba(0,0,0,0.1)', padding: '10px', lineHeight: '18px'}"
        >
          <div v-if="!caculateInfo.prime_points">
            <span>{{ language.SHEIN_KEY_PC_15761 }}<br />{{ language.SHEIN_KEY_PC_22344 }}</span>
          </div>
          <div
            v-if="pointsTotal"
            class="points-reward__pop-total"
            v-html="pointsTotal"
          ></div>
          <template #reference>
            <a
              class="point-shein c-icon-tip"
              href="javascript:;"
              @click="jumpPointInfo"
            >
              {{ language.SHEIN_KEY_PC_14707 }}
            </a>
          </template>
        </s-popover>
      </span>
    </template>
    <template v-else-if="caculateInfo.totalPointMultiple != 1">
      <span
        v-if="showDoubleLogo"
        class="point-container"
      >
        <img
          :src="
            !GB_cssRight
              ? IMG_LINK['points_head_left']
              : IMG_LINK['points_head_right']
          "
          class="point-img"
        />
        <span
          v-if="caculateInfo.totalPointMultiple == 2"
          :class="[!IS_RW ? 'point-multi' : 'point-multi-rw']"
        >
          {{ language.SHEIN_KEY_PC_17965 }}
        </span>
        <span
          v-if="caculateInfo.totalPointMultiple == 3"
          :class="[!IS_RW ? 'point-multi' : 'point-multi-rw']"
        >
          {{ language.SHEIN_KEY_PC_17963 }}
        </span>
        <span
          v-if="caculateInfo.totalPointMultiple == 4"
          :class="[!IS_RW ? 'point-multi' : 'point-multi-rw']"
        >
          {{ language.SHEIN_KEY_PC_17964 }}
        </span>
        <img
          :src="
            !GB_cssRight
              ? IMG_LINK['points_tail_left']
              : IMG_LINK['points_tail_right']
          "
          class="point-img"
        />
      </span>

      {{ language.SHEIN_KEY_PC_14748 }}

      <span>
        <span class="point-red">{{ caculateInfo.totalPoints }}</span>
        <template
          v-if="
            caculateInfo.totalPointMultiple != 1 &&
              caculateInfo.rewardPoints &&
              caculateInfo.getDoublePoints
          "
        >
          <span class="point-black">( {{ caculateInfo.rewardPoints }} +</span>
          <span class="point-red">{{ caculateInfo.getDoublePoints }}</span>
          <span class="point-black">)</span>
        </template>
        <s-popover
          placemen="bottom-end"
          trigger="hover"
          :prop-style="{
            width: '266px',
            boxShadow: '0px 2px 10px 2px rgba(0,0,0,0.1)',
            padding: '10px',
            lineHeight: '18px',
          }"
        >
          <span>{{ language.SHEIN_KEY_PC_15761 }}<br />{{
            language.SHEIN_KEY_PC_22344
          }}</span>
          <template #reference>
            <a
              class="point-shein c-icon-tip"
              href="javascript:;"
              @click="jumpPointInfo"
            >
              {{ language.SHEIN_KEY_PC_14707 }}
            </a>
          </template>
        </s-popover>
      </span>
    </template>
    <template v-else-if="caculateInfo && caculateInfo.rewardPoints * 1 > 0">
      {{ language.SHEIN_KEY_PC_14748 }}
      <span class="point-red">{{ caculateInfo.rewardPoints }}</span>
      <s-popover 
        placemen="bottom-end" 
        trigger="hover"
        :prop-style="{width: '266px', boxShadow: '0px 2px 10px 2px rgba(0,0,0,0.1)', padding: '10px', lineHeight: '18px'}"
      >
        <span>{{ language.SHEIN_KEY_PC_15761 }}<br />{{ language.SHEIN_KEY_PC_22344 }}</span>
        <template #reference>
          <a
            class="point-shein c-icon-tip"
            href="javascript:;"
            @click="jumpPointInfo"
          >
            {{ language.SHEIN_KEY_PC_14707 }}
          </a>
        </template>
      </s-popover>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { template as _commonTemplate } from '@shein/common-function'
import primeVipIcon from 'public/src/pages/common/prime/primeVipIcon.vue'
import { Icon } from '@shein-aidc/icon-vue3'


export default {
  components: {
    primeVipIcon,
    Icon,
  },
  computed: {
    ...mapState([
      'checkout',
      'language',
      'locals',
    ]),
    GB_cssRight() {
      return this.locals.GB_cssRight
    },
    IMG_LINK() {
      return this.locals.IMG_LINK
    },
    SiteUID() {
      return this.locals.SiteUID
    },
    IS_RW() {
      return this.locals.IS_RW
    },
    caculateInfo() {
      return this.checkout?.mall_caculate_info || {}
    },
    carts() {
      return this.checkout?.results?.carts?.carts || []
    },
    // 当此次下单的商品中存在不可参与多倍积分活动的商品，或当前为romwe的墨西哥站点时，不展示倍数LOGO
    showDoubleLogo() {
      return this.SiteUID == 'rwmx' ||
        this.carts.filter((item) => item.product.double_status == 0).length
        ? false
        : true
    },
    pointsTotal() {
      if (!this.caculateInfo?.point_grant_amount_info?.cycle_grant_amount) return ''
      const info = this.caculateInfo?.point_grant_amount_info
      const oldParams = [info?.cycle_used_grant_amount, info?.cycle_grant_amount]
      const maxPoints = info?.single_order_reward_points_limit || info?.cycle_grant_amount
      return this.IS_RW ? 
        this.template(...oldParams, this.language.SHEIN_KEY_PC_24015) :
        this.template(...oldParams, maxPoints, this.caculateInfo?.prime_points, this.language.SHEIN_KEY_PC_31537)
    },
  },
  methods: {
    template: _commonTemplate,
    jumpPointInfo() {
      window.open(
        `${gbCommonInfo?.forceHttp}/bonus-point-program-a-371.html`,
        '_blank',
        'width=600,height=600,resizable=1,scrollbars=1,toolbar=1,status=1,left=50,top=100'
      )
    },
  },
}
</script>

<style lang="less">
.points-reward__pop-total {
  margin-top: 8px;
}
.prime-icon {
  color: @sui_color_club_rosegold_dark2;
}
.point-prime {
  color: @sui_color_club_rosegold_dark2;
  .prime-vip-icon-center{
    transform: translateY(1px);
  }
}
.c-order-summary .point-tip{
  color: #767676;
  .txt-r();
  margin-bottom: 10px;
  .point-red {
          color: @sui_color_highlight;
  }
  .point-black {
    color: #222;
  }
  .point-shein {
    text-decoration: none;
    & when(@IS_RW) {
      color: @sui_color_main;
      font-weight: bold;
      &:hover{
        text-decoration: underline;
      }
    }
    & when(@IS_SH) {
      color: @sui_color_main;
      border-bottom: 1px dotted #666;
      &:hover{
        text-decoration: none;
      }
    }
  }
  .point-container {
    font-size: 0;
    display: inline-flex;
    vertical-align: middle;
  }
  .point-img {
    height: 20px;
  }
  .point-multi {
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    color: #F5A623;
    font-style: italic;
    height: 20px;
    padding: 5px;
    background-image: url(/she_dist/images/points/points-middle.png);
    background-size: auto 20px;
  }
  .point-multi-rw {
    display: inline-block;
    font-size: 9px;
    font-weight: bold;
    color: #FF696E;
    font-style: italic;
    height: 20px;
    padding: 5px 8px 5px 5px;
    background-image: url(/she_dist/rw_images/points/points-middle.png);
    background-size: auto 20px;
  }
}
</style>
