<template>
  <ul 
    class="activity-popover__goods-list"
  >
    <template
      v-for="(item, index) in goodsList"
    >
      <GoodsItem 
        v-if="index < 2"
        :key="item.id"
        :item="item"
        :language="language"
        :out-quantity="item.cart_count"
        :under-price-abt-value="underPriceAbtValue"
      />
    </template>
  </ul>
</template>

<script>
import GoodsItem from './GoodsItem'

export default {
  name: 'GoodsList',
  components: {
    GoodsItem,
  },
  props: {
    language: {
      type: Object,
      default: () => {}
    },
    goodsList: {
      type: Array,
      default: () => []
    },
    underPriceAbtValue: {
      type: Boolean,
      default: false
    },
  },
  watch: {
    goodsList: {
      handler(n) {
        this.handleOutQuantity(n)
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    handleOutQuantity(list) {
      if(list.length > 2) list[1].cart_count = list.length - 2
    },
  }
}
</script>

<style lang="less" scoped>
.activity-popover__goods-list {
  display: flex;
}
</style>
