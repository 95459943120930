<template>
  <div>
    <GoodsList 
      v-if="lowStockCartsData.length"
      class="j-activity-left"
      :language="language"
      :goods-list="lowStockCartsData"
      :under-price-abt-value="underPriceAbtValue"
    />
    <Tips 
      v-if="tipsDescription && tipsDescription.length"
      :language="language"
      :icon="tipsIcon"
      :description="tipsDescription"
    />
  </div>
</template>

<script>
import { template } from '@shein/common-function'
import GoodsList from './goods_info/GoodsList.vue'
import Tips from './Tips.vue'
import { iconConfig } from './config'

export default {
  name: 'LowstockPopover',
  components: {
    GoodsList,
    Tips,
  },
  props: {
    checkout: {
      type: Object,
      default: () => {}
    },
    language: {
      type: Object,
      default: () => {}
    },
    underPriceAbtValue: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    // 低库存的数据
    lowStockCartsData() {
      const cartsInfo = this.checkout.results?.carts?.carts || []
      return cartsInfo.filter(product => Number(product?.stock_show_type) === 0)
    },
    tipsDescription() {
      if (this.lowStockCartsData.length === 1) {
        const stockNum = this.lowStockCartsData[0]?.product?.stock || 0
        return `<em>${this.template(stockNum, this.language.SHEIN_KEY_PC_29247)}</em> ${this.language.SHEIN_KEY_PC_29248}`
      }
      return `${this.language.SHEIN_KEY_PC_29249} <em>${this.language.SHEIN_KEY_PC_29250}</em>`
    },
    tipsIcon() {
      return iconConfig?.stock || {}
    },
  },
  methods: {
    template,
  }
}
</script>
