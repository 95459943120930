<template>
  <div
    v-expose="{
      id: '1-11-1-204',
      data: {
        is_choosed: showCouponHint ? 1 : 0,
        is_folded: isFolded,
      }
    }"
    class="check-coupon assets-item__effiency"
  >
    <div class="indicates assets-item__coupon-title">
      <div>
        <span class="assets-item-title">{{ language.SHEIN_KEY_PC_14740 }}</span>
        <template v-if="couponListNotice">
          <s-popover
            class="assets-icon-tip"
            placemen="bottom"
            trigger="hover"
          >
            <div v-html="couponListNotice"></div>
            <template #reference>
              <sui_icon_doubt_16px_1
                class="assets-icon-tip__icon"
                size="14px"
                color="#767676"
              />
            </template>
          </s-popover>
        </template>
      </div>
      <p
        v-show="status.hasAddress != 0"
        class="point-link assets-item__tip"
        :class="{ 'red-point-tips': showRedPoints }"
        tabindex="0"
      >
        <a
          class="coupon-link"
          role="button"
          href="javascript:void(0);"
          @click="showCouponsList"
        >{{ language.SHEIN_KEY_PC_15952 }}</a>
      </p>
    </div>
    <div class="apply-box she-clearfix">
      <div class="c-input she-fl c-input-coupon">
        <s-input
          v-model="couponData"
          type="text"
          readonly
          onfocus="this.removeAttribute('readonly');"
          aria-describedby="coupon-ada-tip"
          name=""
          :class="[{ 'input-error-bg': showCouponError }]"
          :disabled="status.hasAddress == 0 ? true : false"
          :placeholder="lang == 'ru' ? 'Введите код купона' : ''"
          :aria-label="language.SHEIN_KEY_PC_14740"
          @input="focusCouponInput"
        />
      </div>

      <s-button
        v-if="status.hasAddress == 0"
        disabled
        :type="['H36PX']"
        :min-width="'96px'"
        :max-width="'131px'"
        class="she-fr apply-box-btn"
      >
        {{ language.SHEIN_KEY_PC_14744 }}
      </s-button>
      <s-button
        v-else
        :type="['H36PX', coupon.couponLoading ? 'primary' : 'default']"
        :min-width="'96px'"
        :max-width="'131px'"
        class="she-fr apply-box-btn"
        @click="apply"
      >
        <div v-show="coupon.couponLoading">
          <div class="la-ball-pulse la-ball-pulse-white">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <span v-show="!coupon.couponLoading">
          {{
            checkAfterCoupon
              ? language.SHEIN_KEY_PC_15585
              : language.SHEIN_KEY_PC_14744
          }}
        </span>
      </s-button>
    </div>

    <CheckoutFreeShipDiffTip
      v-if="hasShipPriceDiff.length"
      class="assets-item__tip"
      :has-ship-price-diff="hasShipPriceDiff"
      :checkout="checkout"
      :language="language"
    />
    <PickCouponGift
      v-if="showCouponGift"
      class="assets-item__tip"
      :language="language"
      :carts="checkout.results.carts.carts"
    />
    <p
      v-if="showCouponHint"
      class="coupon-prime-tips assets-item__tip"
      v-html="usedTips"
    ></p>
    <p
      v-show="showCouponError"
      id="coupon-ada-tip"
      class="error-tip assets-item__tip"
      tabindex="0"
    >
      <span>
        {{ couponCodeErrorTips }}
      </span>
    </p>
    <!-- 首单优惠提示 -->
    <p
      v-if="firstCouponHtml"
      :class="['first-order-off', 'flash-red', 'assets-item__tip']"
      v-html="firstCouponHtml"
    ></p>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import { htmlDecode, template as _commonTemplate } from '@shein/common-function'
import checkoutMixin from 'public/src/pages/checkout/checkoutMixin.js'
import couponMixin from 'public/src/pages/checkout/mixins/couponMixin'
import { daEventCenter } from 'public/src/services/eventCenter/index'

import { sui_icon_doubt_16px_1 } from '@shein-aidc/icon-vue3'

import CheckoutFreeShipDiffTip from './CheckoutFreeShipDiffTip.vue'
import PickCouponGift from 'public/src/pages/checkout/components/coupon_gift/PickCouponGift.vue'

daEventCenter.addSubscriber({ modulecode: '1-11-1' })


export default {
  components: {
    CheckoutFreeShipDiffTip,
    PickCouponGift,
    sui_icon_doubt_16px_1,
  },
  mixins: [checkoutMixin, couponMixin],
  props: {
    isFolded: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    ...mapState([
      'hasAvailableCoupon',
      'firstCouponHtml',
      'couponGift',
      'coupon',
      'status',
      'checkout',
      'applyType',
      'freeShipTip',
      'usedPrimeCode', 
      'couponListNotice',
      'hasEnterCouponList',
      'usableCouponList',
      'locals',
    ]),
    ...mapGetters(['showNewCouponLanguage']),
    lang() {
      return this.locals.lang
    },
    showRedPoints() {
      // 当前未用券，存在可用券，未进入过券列表
      return this.hasAvailableCoupon && !this.hasEnterCouponList && !this.applyCouponsSuccess.length
    },
    showCouponHint() {
      return this.applyCouponsSuccess.length > 0
    },
    couponData: {
      get() {
        return this.$store.state.couponData
      },
      set(newVal) {
        this.$store.commit('assignState', {
          couponData: newVal,
        })
      },
    },
    checkAfterCoupon() {
      return (
        this.couponData &&
        this.coupon.lastCoupon == this.couponData.toLowerCase()
      )
    },
    showCouponError() {
      return this.couponErrorShow && this.applyType == 2
    },
    showCouponSuccess() {
      return (
        this.checkout?.mall_caculate_info?.memberFreeShippingCoupon
          ?.is_checked == 1 ||
        (this.applyCouponsSuccess.length && this.checkAfterCoupon)
      )
    },
    hasShipPriceDiff() {
      const { coupon, other, otherType } = this.freeShipTip
      let newShipList = []
      if (coupon.length) newShipList = coupon
      if (otherType == 'coupon' && other.length)
        newShipList = newShipList.concat(other)
      return newShipList
    },
    showCouponGift() {
      return this.couponGift && !!this.checkout.results?.carts?.carts?.length
    },
    usedTips() {
      if (this.showNewCouponLanguage && this.coupon.isAutoUse) return this.language.SHEIN_KEY_PC_31138
      return this.template(
        this.applyCouponsSuccess.length, 
        this.language.SHEIN_KEY_PC_24011
      )
    }
  },
  watch: {
    usedPrimeCode: {
      handler(n, o) {
        if(typeof window == 'undefined') return
        // 购买付费会员产品包状态发生变化时重新获取券列表公告 
        if (!!n != !!o) this.getCouponListNotice()
      },
      immediate: true
    },
  },
  methods: {
    ...mapMutations([
      'updateCheckoutState',
      'changeParamsStatus',
      'assignState',
    ]),
    ...mapActions(['getCouponListNotice']),
    htmlDecode,
    template: _commonTemplate,
    apply() {
      const couponData = this.couponData.trim()
      const isIncludeList = this.judgeAddCoupon(couponData)
      if (!couponData) {
        this.status.error.coupon = 1
        this.status.error.couponCode = 0

        //ada聚焦提示
        this.$nextTick(() => {
          document.querySelector('.c-input-coupon input')?.focus()
        })
        return
      }

      if (!this.checkAfterCoupon && !isIncludeList) {
        this.assignState({ couponsApply: true })
      }
      this.assignState({ applyType: 2 })

      this.newApplyCoupon({
        item: {
          coupon: couponData,
        },
      })
    },
    showCouponsList() {
      // 只有第一次展示红点
      this.changeParamsStatus({
        type: 'coupon',
        params: {
          showCouponList: true,
        },
      })
      this.updateCheckoutState({
        key: 'hasEnterCouponList',
        val: true,
      })
      daEventCenter.triggerNotice({
        daId: '1-11-1-133'
      })
      const { applyCouponsSuccess = [] } = this.coupon || {}
      daEventCenter.triggerNotice({
        daId: '1-11-1-176',
        extraData: {
          available_coupon: !!this.usableCouponList.length ? 1 : 0,
          used_coupon: applyCouponsSuccess?.length ? 
            applyCouponsSuccess?.map(coupon => coupon.couponCode).join(',') : 
            '-',
          is_choosed: this.showCouponHint ? 1 : 0,
          is_folded: this.isFolded,
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.coupon-prime-tips {
  margin-top: 10px;
  color: @sui_color_gray_dark3;
  font-size: 12px;
}
.point-link {
  .coupon-link {
    .margin-l(4px);
  }
}
.assets-item__coupon-title {
  display: flex;
  justify-content: space-between;
  .assets-icon-tip__icon {
    margin-left: 5px;
  }
}
.check-coupon{
		margin: 5px 0 30px 0;
		.apply-box{
			margin-top: 3px;
			.c-input{
				width: calc(~"65% - 10px");
				input:disabled{
					color: #999;

					/* ipad下safari的input disabled字体不明显兼容 */
					opacity: 1;
					-webkit-text-fill-color: #999;
					-moz-text-fill-color: #999;
					-ms-text-fill-color: #999;
					-o-text-fill-color: #999;
				}
        input{
          &::-webkit-input-placeholder,
          &:-moz-placeholder,
          &::-moz-placeholder,
          &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: #bbbbbb;
          }
        }
				input.input-error-bg{
					border-color: @sui_color_unusual;
					background-color: @sui_color_unusual_bg;
				}
			}
			>a{
				width: 35%;
				display: block;
			}
		}

		.apply-box{
			.card-num{
				width: calc(~"47% - 10px");
			}
			.password{
				width: 17%;
				.margin-l(1%);
				margin-top: 0;
			}
			>a{
				width: 35%;
				display: block;
			}
			.la-ball-pulse{
				>div{
					background-color:#fff;
				}
			}
		}
		.error-tip{
			margin-top: 5px;
			color: @sui_color_unusual;
		}
		.warn-tip{
			margin-top: 5px;
		}
		.success-tip{
			margin-top: 5px;
			// margin-bottom: 5px;
			color: @sui_color_success;
		}
		.point-link{
			margin-top: 5px;
			color: #767676;
			display: inline-block;
			text-decoration: none;
			a {
				cursor: pointer;
				color: @sui_color_link;
			}
		}
		.first-order-off{
			margin-top: 10px;
		}
		.flash-red #message {
			color: red;
    }
    .red-point-tips {
      a{ position: relative ; }
      a:after {
        content: '';
        display: block;
        position: absolute;
        width: 4px;
        height: 4px;
        background: #FF411C;
        border-radius: 50%;
        top: 0;
        .right(-8px);
      }
    }
	}
@media (max-width: 1440px) {
	.c-order-summary{
		.check-coupon{
			margin: 0 0 16px 0;
		}
		.check-coupon-bottom {
			margin-bottom: 0;
		}
	}
}
</style>
