<template>
  <div class="checkout-order-summary fsp-element">
    <h4 class="checkout-order-summary__title">
      {{ language.SHEIN_KEY_PC_14764 }}
    </h4>
    <div class="checkout-order-summary__body">
      <!-- 价格明细 -->
      <CheckoutSummaryPrice 
        v-expose="{
          id: '1-11-1-201',
        }"
        :is-discount-total-color="isDiscountTotalColor"
        :pre-pay-un-pay-params="prePayUnPayParams"
      />
      <!-- 积分提示 -->
      <CheckoutSummaryPointTips
        v-if="caculateInfo.totalPointMultiple && caculateInfo.totalPoints"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import CheckoutSummaryPrice from 'public/src/pages/checkout/components/summary/price/PriceInfo.vue'
import CheckoutSummaryPointTips from 'public/src/pages/checkout/components/summary/CheckoutSummaryPointTips.vue'


export default {
  name: 'OrderSummaryEffiency',
  components: {
    CheckoutSummaryPrice,
    CheckoutSummaryPointTips
  },
  props: {
    isDiscountTotalColor: Boolean,
    prePayUnPayParams: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  computed: {
    ...mapState([
      'checkout',
      'language',
    ]),
    caculateInfo() {
      return this.checkout?.mall_caculate_info || {}
    },
  }
}
</script>

<style lang="less">
.checkout-order-summary {
  padding: 16px;
  margin-bottom: 10px;
  background: #fff;

  &__title {
    font-family: Arial Black;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 8px;
    color: @sui_color_gray_dark1;
    text-transform: capitalize;

    & when (@IS_RW) {
      font-family: Adieu;
    }
  }

  &__body {
    .checkout-summary-price {
      margin: -4px 0;
    }
    .summary-item {
      line-height: 15px;
      font-size: 13px;
      margin-bottom: 0;

      padding: 4px 0;

      &.total-item-wrap {
        line-height: 25px;
        .flexbox();
        .item-left {
          font-family: Arial;
        }

        .item-right {
          text-align: right;
          flex-grow: 1;
          flex-shrink: 0;
          flex-basis: max-content;
          font-family: Arial Black;
          font-size: 18px;
        }
      }

      &.gov-tips {
        margin-top: 2px;
        color: @sui_color_gray_dark2;
      }
    }

    .checkout-summary-price .summary-item del {
      color: @sui_color_gray_light1; 
    }

    .order-summary-taxes__wrap {
      margin: 4px 0;
    }

    .point-tip {
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 0;
      margin-top: 8px;
      color: @sui_color_gray_dark1;

      .point-shein {
        border: none;
        padding: 0 0 0 5px;
      }
    }

    .summary-coupon-txt {
      text-align: left;
      margin-top: 2px;
    }

    .checkout-options__alert-effiency {
      padding: 10px;
      margin-top: 8px;
    }

    .order-summary-taxes {
      padding-bottom: 8px;
    }

    .prime-discount-info {
      margin: 2px 0 0;
      color: @sui_color_gray_light1;
    }

    .text-red {
      color: @sui_color_welfare_dark;
    }

    [class*="sui_icon_doubt"] {
      font-size: 14px;
      color: @sui_color_gray_dark3;
    }
  }
}
</style>
