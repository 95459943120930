<template>
  <div 
    v-if="isShowShipSwitch || isShowShipJoin"
    class="ship-prime-wrap not-fsp-element"
  >
    <div
      v-if="isShowShipSwitch"
      class="ship-methods-prime"
    >
      <span class="prime-des">
        {{ shipSwitchTxt }}
      </span>
      <span
        class="prime-switch"
        @click="goCouponPage"
      >
        {{ language.SHEIN_KEY_PC_24010 }}
      </span>
    </div>

    <div
      v-if="isShowShipJoin"
      v-expose="{
        id: '1-11-1-106',
        data: shipJoinPrimeAnalysis
      }"
      class="join-prime"
    >
      <span class="prime-free">
        <sui_icon_club_logo_16px
          class="prime-free__icon"
          color="#873c00"
        />
        <span v-html="joinFreeTxt"></span>
      </span>
      <span 
        v-tap="{
          id: '1-11-1-107',
          data: shipJoinPrimeAnalysis
        }"
        class="prime-btn" 
        @click="joinPrime"
      >
        <span>
          {{ language.SHEIN_KEY_PC_25147 }}
        </span>
      </span>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { template as _commonTemplate } from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { tap, expose } from 'public/src/pages/common/analysis/directive.js'
import { sui_icon_club_logo_16px } from '@shein-aidc/icon-vue3'

daEventCenter.addSubscriber({ modulecode: '1-11-1' })

export default {
  name: 'ShippingPrime',
  components: {
    sui_icon_club_logo_16px,
  },
  directives: { tap, expose },
  props: {
    mallCode: {
      type: [String, Number],
      default: '',
    },
    defaultShippingItem: {
      type: Object,
      default: () => {}
    },
    primeCouponCount: {
      type: Number,
      default: 0
    },
  },
  computed: {
    ...mapState([
      'language',
      'checkout',
      'showShipSwitch', 
      'usedPrimeCode', 
      'primeRightCouponList', 
      'primeProductList',
      'selectdPrimeIndex',
      'usedPrimeIndex',
      'rightConfigList',
    ]),
    caculateInfo() {
      return this.checkout?.mall_caculate_info || {}
    },
    shippingPrimeWordVersion() {
      return this.checkout?.checkoutBFFAbtInfo?.FreeShippingPrimeWord?.param?.FreeShippingPrimeSwitchWord == 1 ?  1 : 0
    },
    isShowShipSwitch() {
      const isShow = this.showShipSwitch && this.primeCouponCount > 0

      return isShow && !!this.shipSwitchTxt
    },
    shipSwitchTxt() {
      return this.shippingPrimeWordVersion ? this.primeCouponTipsV2 : this.primeCouponTips
    },
    primeCouponTips() {
      return this.template(this.primeCouponCount, this.language.SHEIN_KEY_PC_24009)
    },
    primeCouponTipsV2() {
      if(this.usedPrimeCode) { // 当前购买产品包
        const usedPrime = this.primeProductList[this.usedPrimeIndex] || {}
        const primePrice = usedPrime.product_price_info?.special_price || 0
        const isDiscount = this.caculateInfo.shippingCouponPrice?.amount >= primePrice
        const freeRight = this.rightConfigList.find(item => item.right_type_code == 'FREE_SHIPPING_TIMES') || {}

        if(+this.caculateInfo.actual_shipping_price?.amount > 0) {
          if(+freeRight.coupon_value <= 0) return ''

          return this.template(freeRight.coupon_value_with_symbol, isDiscount ? this.language.SHEIN_KEY_PC_29878 : this.language.SHEIN_KEY_PC_28117)
        } else {
          return this.template(this.primeCouponCount, isDiscount ? this.language.SHEIN_KEY_PC_29878 : this.language.SHEIN_KEY_PC_29879)
        }
      } else { // 当前已经是付费会员
        return +this.caculateInfo.actual_shipping_price?.amount > 0 ? this.template(this.caculateInfo.shippingCouponPrice?.amountWithSymbol, this.language.SHEIN_KEY_PC_28114) : this.template(this.primeCouponCount, this.language.SHEIN_KEY_PC_28113)
      }
    },
    couponTpSa() {
      let coupon_tp = '0'
      if (this.actualShippingPrice?.amountWithSymbol && +this.actualShippingPrice?.amount == 0) {
        coupon_tp = '1'
      } else if (+this.actualShippingPrice?.amount < +this.originShippingPrice?.amount) {
        coupon_tp = '2'
      } else {
        coupon_tp = '0'
      }
      return coupon_tp
    },
    shippingPriceInfo() {
      const { mall_list = [] } = this.checkout?.mall_caculate_info || {}
      const filterCalcu = mall_list.find(mall => mall.mall_code == this.mallCode) || {}

      return filterCalcu
    },
    actualShippingPrice() {
      const transport_type = this.defaultShippingItem?.shipping_method?.transport_type || ''
      const shipping_price_all = this.shippingPriceInfo.shipping_price_all || []
      const shipping_price = shipping_price_all.filter(price => price.transport_type == transport_type) || []
      return shipping_price[0]?.price || {}
    },
    originShippingPrice() { // 活动前运费
      return this.shippingPriceInfo.shipping_price || {}
    },
    commonAnalysis() {
      return { coupon_tp: this.couponTpSa, mall_code: this.mallCode || '' }
    },
    shippingDiscountType() {
      const transport_type = this.defaultShippingItem?.shipping_method?.transport_type || ''
      // 免邮抵扣类型：0全免 1部分抵扣(兜底兼容增加类型 -1，即不存在邮费相关券)
      let type = -1
      if(!this.primeRightCouponList.length || !transport_type) return { type, couponList: [] }

      // coupon_dimension 1-平台券 2–mall券;applyFor5/6/9免邮券
      const mallCouponList = this.primeRightCouponList.filter(item => (item.shippingMethod?.includes(transport_type) && (item.couponDimension == 1 || item.mall?.mallCode == this.mallCode) && [5, 6, 9].includes(+item.applyFor))) || []
      const canUseCouponList = this.shippingPrimeWordVersion ? (mallCouponList.filter(item => +item.rule?.[0]?.minAmount?.amount <= +this.caculateInfo.newSubTotal?.amount) || []) : mallCouponList

      const typeList = canUseCouponList.map(item => +item.shippingDiscountType) || []
      if(typeList.includes(1)) type = 1
      if(typeList.includes(0)) type = 0 // 只要存在全免券，即展示全免文案
      
      return { type, couponList: canUseCouponList }
    },
    joinFreeTxt() {
      return this.shippingPrimeWordVersion ? this.joinFreeTipsV2.text : this.joinFreeTips
    },
    joinFreeTips() {
      const { SHEIN_KEY_PC_25154, SHEIN_KEY_PC_25153 } = this.language
      const { type } = this.shippingDiscountType
      if(type == -1) return ''
      return [SHEIN_KEY_PC_25154, SHEIN_KEY_PC_25153][type]
    },
    joinFreeTipsV2() {
      const { type, couponList } = this.shippingDiscountType
      if(type == -1 || !couponList.length) {
        return { 
          text: '',
          type: '-'
        }
      } 

      let couponDiscountAmount = 0
      if(type == 0) { // 存在全免券
        couponDiscountAmount = +this.originShippingPrice.amount || 0
      } else {
        couponDiscountAmount = couponList.reduce((prev, curr) => {
          prev += +curr.rule?.[0]?.valueAmount?.amount ? +curr.rule?.[0]?.valueAmount?.amount : 0
          return prev
        }, 0)
      }
     
      const selectedPrime = this.primeProductList[this.selectdPrimeIndex] || {}
      const primePrice = selectedPrime.product_price_info?.special_price || 0
      const isDiscount = couponDiscountAmount >= primePrice
      const freeRight = this.rightConfigList.find(item => item.right_type_code == 'FREE_SHIPPING_TIMES') || {}

      if(couponDiscountAmount < +this.originShippingPrice.amount) {
        if(+freeRight.coupon_value <= 0) {
          return { 
            text: '',
            type: '-'
          }
        }

        if(isDiscount) {
          return {
            text: this.template(freeRight.coupon_value_with_symbol, this.language.SHEIN_KEY_PC_28120),
            type: 'partial_freeshipping_break'
          }
        } else {
          const shippingCouponList = this.primeRightCouponList.filter(item => ([5, 6, 9].includes(+item.applyFor))) || []

          return {
            text: this.template(shippingCouponList.length, freeRight.coupon_value_with_symbol, this.language.SHEIN_KEY_PC_28121),
            type: 'partial_freeshipping_nobreak'
          }
        }
      } else {
        return isDiscount ? {
          text: this.language.SHEIN_KEY_PC_28118,
          type: 'total_freeshipping_break'
        } : {
          text: this.template(freeRight.coupon_value_with_symbol, this.language.SHEIN_KEY_PC_29880),
          type: 'total_freeshipping_nobreak'
        }
      }
    },
    showPrimeFreeJoinByAbt () {
      return this.checkout?.checkoutBFFAbtInfo?.FreeShippingPrime?.param?.FreeShippingPrimeSwitch == 1 ?  true : false
    },
    isShowShipJoin() {
      // 已勾选产品包、当前权益中不包含运费折扣、当前mall选中运输方式已满足免邮、abt开启
      const { type } = this.shippingDiscountType
      if (this.usedPrimeCode || type == -1 || +this.actualShippingPrice?.amount == 0 || !this.showPrimeFreeJoinByAbt) return false

      return !!this.joinFreeTxt
    },
    shipJoinPrimeAnalysis() {
      if(typeof window == 'undefined') return {}

      let texttype = ''
      const { type } = this.shippingDiscountType
      if(!this.shippingPrimeWordVersion) {
        if(type != -1) texttype = ['total_freeshipping', 'partial_freeshipping'][type] || ''
      } else {
        texttype = this.joinFreeTipsV2.type
      }
      
      return { texttype, prime_level: '0', total_saving: '0', location: 'page', prime_promotion: '0', expiring_flag: '-', renew_flag: '0' }
    },
  },
  watch: {
    'actualShippingPrice.amount': {
      handler(n, o) {
        if(typeof window == 'undefined') return
        
        // 增加定时器，等待isShowShipSwitch赋值完成后再执行 
        setTimeout(() => {
          if (n != o && this.isShowShipSwitch) {
            daEventCenter.triggerNotice({
              daId: '1-11-1-94',
              extraData: this.commonAnalysis
            })
          }
        }, 500)
      },
      immediate: true
    },
  },
  methods: {
    ...mapMutations(['assignState', 'changeParamsStatus', 'updateCheckoutState']),
    template: _commonTemplate,
    goCouponPage () {
      daEventCenter.triggerNotice({
        daId: '1-11-1-95',
        extraData: {
          coupon_tp: this.couponTpSa,
          mall_code: this.mallCode || '',
        }
      })
      // 只有第一次展示红点
      this.changeParamsStatus({
        type: 'coupon',
        params: {
          showCouponList: true
        }
      })
      this.updateCheckoutState({
        key: 'hasEnterCouponList',
        val: true
      })
    },
    joinPrime() {
      this.assignState({
        showPrimeDetail: true,
        primePopFrom: 2,
      })
    },
  }
}
</script>

<style lang="less" scoped>
.ship-prime-wrap {
  margin: -10px 0 16px 0;
}
.ship-methods-prime {
  background: @sui_color_promo_bg;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  font-size: 12px;
  .prime-des {
    color: @sui_color_club_rosegold_dark1;
  }
  .prime-switch {
    color: @sui_color_link;
    cursor: pointer;
  }
}
.join-prime {
  background: #FFF2E7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  font-size: 12px;
  color: @sui_color_club_rosegold_dark1;
  .prime-free {
    display: flex;
    align-items: center;
    line-height: 16px;
    &__icon {
      .padding-r(8px);
    }
  }
  .prime-btn {
    padding: 5px 8px;
    border-radius: 34px;
    flex-shrink: 0;
    min-width: 40px;
    max-width: 104px;
    border: 1px solid @sui_color_club_rosegold_dark1;
    cursor: pointer;

    span {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      word-break: break-all;
    }
  }
}
</style>
