import { scenceBuildResult } from './tools'
import { deepCopy } from 'public/src/pages/checkout/utils.js'

export default {
  upDateSceneStatusNew(state){
    // 超省卡自动续费改动
    const { usedXtraCode, coupon, checkout, updatingPrice, usableCouponList, xtraAllProductBagInfo } = state
    const { save_card_activity_discount_price = {}, save_card_price = {}, save_card_price_after_coupon = {}, save_card_total_discount_price = {}, save_card_coupon_discount_price = {} } = checkout?.mall_caculate_info || {}
    const xtraProductAll = Object.values(xtraAllProductBagInfo)
    xtraProductAll.forEach(item => {
      const resultData = item?.productInfo
      const productPriceInfo = resultData?.product_price_info || {} // 超省卡价格信息
      const couponRightsCode = productPriceInfo?.product_dues_coupon_rights?.coupon_code // 会费券码
      const isPreciseType = productPriceInfo.promotion_type == 5 // 人群价
      const _specialPriceNum = resultData?.product_price_info?._specialPriceNum || deepCopy(productPriceInfo.special_price) // 产品包自身活动价格（不包含券）
      const showDiscount = productPriceInfo?.price_local && productPriceInfo?._specialPriceNum && (+productPriceInfo.price_local > +productPriceInfo._specialPriceNum) //产品包特价
      const selectUsedXtraCode = usedXtraCode === resultData.product_code // 选中产品包和遍历对应的包体
      const { total_discount_price, total_discount_price_with_symbol, arrival_price_with_symbol, arrival_price } = productPriceInfo
      // 超省卡会费券59
      const usableCouponListVir = usableCouponList.filter(item => [59].includes(+item.real_type_id)).map(i => i.coupon)
      if(couponRightsCode && !usableCouponListVir.includes(couponRightsCode)){
        usableCouponListVir.push(couponRightsCode)
      }
      const applyCouponsVir = coupon.applyCouponsSuccess.some(item => (usableCouponListVir.includes(item.couponCode) && item.couponCode == couponRightsCode)) // 使用会费券成功
      const reducePriceMap = applyCouponsVir ? save_card_total_discount_price : save_card_activity_discount_price //优惠金额
      const specialPriceMap = applyCouponsVir ? save_card_price_after_coupon : save_card_price // 到手价
    
      const couponPriceMap = applyCouponsVir ? save_card_coupon_discount_price.amountWithSymbol : productPriceInfo?.product_dues_coupon_rights?.discount_value_with_symbol //券优惠金额
      // _discountPrice、_conponPrice、_specialPriceNum深拷贝初始值，不随后续更改
      Object.assign(resultData.product_price_info, {
        _discountPrice: resultData?.product_price_info?._discountPrice || deepCopy(productPriceInfo.reduce_price_with_symbol), // 产品包自身优惠金额
        _specialPriceWithSymbol: resultData?.product_price_info?._specialPriceWithSymbol || deepCopy(productPriceInfo?.special_price_with_symbol), // 产品包自身到手价带币总（不包含券）
        _conponPrice: couponPriceMap || 0, //券优惠金额
        _specialPriceNum // 产品包自身到手价（不包含券）
      })
    
      if(couponRightsCode && !usedXtraCode){
        // 没勾选，有会费券赋值
        Object.assign(resultData.product_price_info, {
          reduce_price: total_discount_price,
          reduce_price_with_symbol: total_discount_price_with_symbol, // 优惠金额（开卡优惠）
          special_price: arrival_price,
          special_price_with_symbol: arrival_price_with_symbol, // 到手价（开卡价格）
        })
      }
      if(usedXtraCode && !updatingPrice && selectUsedXtraCode){
        // 勾选情况下，取计价接口返回的数据
        let xtraPrice = {
          reduce_price: reducePriceMap.amount,
          reduce_price_with_symbol: reducePriceMap.amountWithSymbol, // 优惠金额（开卡优惠）
          special_price: specialPriceMap.amount,
          special_price_with_symbol: specialPriceMap.amountWithSymbol // 到手价（开卡价格）
        }
        Object.assign(resultData.product_price_info, xtraPrice)
      }
      const result = scenceBuildResult({ showDiscount, isPreciseType, couponRightsCode, usedXtraCode: selectUsedXtraCode, applyCouponsVir })
    
      resultData._xtraStatusInfo = { showDiscount, isPreciseType, couponRightsCode, usedXtraCode: selectUsedXtraCode,  applyCouponsVir } // 会费券场景状态值
      resultData._xtraSceneDiscount = result // 会费券场景
    })

    // console.log('xtraProductAll', xtraProductAll)
  },
  upDatePrimeSceneStatus(state){
    // 输出当前符合的场景 https://wiki.dotfashion.cn/pages/viewpage.action?pageId=1247968743
    const { primeProductList, usedPrimeCode, coupon, checkout, updatingPrice, usableCouponList } = state
    const copyPrimeProductList = primeProductList || []
    const sourceData = checkout.results?.primeProductInfo?.data?.info?.product_info_list || []
    const { prime_price_discount = {}, prime_price = {}, prime_price_after_coupon = {}, prime_total_discount_price = {}, prime_coupon_discount_price = {} } = checkout?.mall_caculate_info || {}
    const scenceAll = copyPrimeProductList.map(item => {
      const { product_price_info: productPriceInfo, product_code } = item
      const couponRightsCode = productPriceInfo?.product_dues_coupon_rights?.coupon_code //会费券码
      const currentResultProPriceInfo = sourceData.find(f => f.product_code === product_code).product_price_info // 当前包体对应的价格信息
      const selectUsedPrimeCode = usedPrimeCode === product_code
      // 付费会员会费券58
      const usableCouponListVir = usableCouponList.filter(item => [58].includes(+item.real_type_id)).map(i => i.coupon)
      if(couponRightsCode && !usableCouponListVir.includes(couponRightsCode)){
        usableCouponListVir.push(couponRightsCode)
      }
      const applyCouponsVir = coupon.applyCouponsSuccess.some(item => (usableCouponListVir.includes(item.couponCode)) && item.couponCode == couponRightsCode) // 使用会费券成功
  
      const { total_discount_price, total_discount_price_with_symbol, arrival_price_with_symbol, arrival_price } = productPriceInfo || {}
      const reducePriceMap = applyCouponsVir ? prime_total_discount_price : prime_price_discount // 优惠金额
      const specialPriceMap = applyCouponsVir ? prime_price_after_coupon : prime_price // 到手价
      const couponPriceMap = applyCouponsVir ? prime_coupon_discount_price.amountWithSymbol : productPriceInfo?.product_dues_coupon_rights?.discount_value_with_symbol //券优惠金额带币总
      const couponPriceAmountMap = applyCouponsVir ? prime_coupon_discount_price.amount : productPriceInfo?.product_dues_coupon_rights?.discount_value //券优惠金额

      const resultData = { ...item }
      const _specialPriceNum = resultData?.product_price_info?._specialPriceNum || deepCopy(productPriceInfo.special_price) // 产品包自身到手价（不包含券）
      const showDiscount = currentResultProPriceInfo?.price_local && currentResultProPriceInfo?._specialPriceNum && (+currentResultProPriceInfo.price_local > +currentResultProPriceInfo._specialPriceNum) //特价

      // 深拷贝存储源数据
      Object.assign(resultData.product_price_info, {
        _discountPrice: resultData?.product_price_info?._discountPrice || deepCopy(productPriceInfo.reduce_price_with_symbol),  // 产品包自身优惠金额带币总
        _discountPriceAmount: resultData?.product_price_info?._discountPriceAmount || deepCopy(String(productPriceInfo.reduce_price)),
        _conponPrice: couponPriceMap || 0, //券优惠金额带币总
        _specialPrice: resultData?.product_price_info?._specialPrice || deepCopy(productPriceInfo.special_price_with_symbol),  // 产品包自身到手价带币总（不包含券）
        _conponPriceAmount: couponPriceAmountMap || 0, //券优惠金额
        _specialPriceNum // 产品包自身到手价（不包含券）
      })

      if(couponRightsCode && !usedPrimeCode){
        // 有会费券，没勾选
        Object.assign(resultData.product_price_info, {
          reduce_price: total_discount_price,
          reduce_price_with_symbol: total_discount_price_with_symbol, // 优惠金额（开卡优惠）
          special_price: arrival_price,
          special_price_with_symbol: arrival_price_with_symbol, // 到手价（开卡价格）
        })
      }
      if(usedPrimeCode && !updatingPrice && selectUsedPrimeCode){
        let primePrice = {
          reduce_price: reducePriceMap.amount,
          reduce_price_with_symbol: reducePriceMap.amountWithSymbol,  // 优惠金额（开卡优惠）
          special_price: specialPriceMap.amount,
          special_price_with_symbol: specialPriceMap.amountWithSymbol,  // 到手价（开卡价格）
        }
        Object.assign(resultData.product_price_info, primePrice)
      }

      let result = 0
      if(showDiscount && couponRightsCode && !selectUsedPrimeCode && !applyCouponsVir) result = 8
      if(showDiscount && couponRightsCode && selectUsedPrimeCode && applyCouponsVir) result = 10
      if(!showDiscount && couponRightsCode && selectUsedPrimeCode && !applyCouponsVir) result = 6
      if(showDiscount && couponRightsCode && selectUsedPrimeCode && !applyCouponsVir) result = 9
      if(showDiscount && !couponRightsCode && !selectUsedPrimeCode) result = 3
      if(showDiscount && !couponRightsCode && selectUsedPrimeCode) result = 4
      return {
        scence: result,
        product_dues_coupon_rights: resultData.product_price_info?.product_dues_coupon_rights,
        product_code: product_code,
        status: { showDiscount, couponRightsCode, selectUsedPrimeCode, applyCouponsVir },
        primeProductList: resultData,
        couponRightsCode,
        currentResultProPriceInfo
      }
    })
    state.virtualCouponInfo.newPrimeProductList = scenceAll
    state.virtualCouponInfo.couponVirtualCode = scenceAll.map(m => m.couponRightsCode)
  },
}
