<template>
  <div
    v-if="isMultiTax"
    class="order-multi-taxes"
    :identification="identification"
  >
    <div class="order-multi-taxes__total-taxes">
      <div class="order-multi-taxes__taxes-text">
        {{ taxsWrapTitle }}
        <s-popover
          :hide-close-icon="true"
          trigger="hover"
          placemen="bottom"
        >
          <p v-html="multiTips"></p>
          <template #reference>
            <span v-if="multiTips && (!isMultiCountryCode || isMYCountryCode || isCLCountryCode)">
              <sui_icon_doubt_14px_1
                size="15px"
                color="#666666"
              />
            </span>
          </template>
        </s-popover>
      </div>
      
      <div
        v-if="isMultiCountryCode"
        class="order-multi-taxes__taxes-text"
        @click.stop="showTaxInfoToggle = !showTaxInfoToggle"
      >
        {{ personAllTax.amountWithSymbol }}
        <component
          :is="showTaxInfoToggle ? 'sui_icon_more_up_14px_1' : 'sui_icon_more_down_14px_1'"
          :is-responsive-name="true"
          size="14px"
          color="#222222"
        />
      </div>
    </div>
    <div
      v-if="(isMultiCountryCode && showTaxInfoToggle) || !isMultiCountryCode"
      class="multi-item-wrapper"
    >
      <div
        v-for="tax in taxesInfo"
        :key="`tax_${tax.taxType}`"
        class="multi-item-wrapper__item"
      >
        <span class="multi-item-wrapper__item-left">
          {{ tax.title }}
          <s-popover
            :hide-close-icon="true"
            trigger="hover"
            placemen="bottom"
          >
            <p v-html="tax.hint"></p>
            <template #reference>
              <span v-if="tax.showIcon">
                <sui_icon_doubt_14px_1
                  class="summary-icon-doubt"
                  size="15px"
                  color="#666666"
                />
              </span>
            </template>
          </s-popover>
        </span>
        <span :class="['multi-item-wrapper__item-right', {'tax-active': tax.taxType === 'company_tax'}]">
          {{ `${ tax.taxType === 'company_tax' ? '-' : '' } ${ tax.taxPrice && tax.taxPrice.amountWithSymbol }` }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue'
import { getTaxesConfig, appendColon } from 'public/src/pages/common/helpers.js'
import { sui_icon_doubt_14px_1, sui_icon_more_up_14px_1, sui_icon_more_down_14px_1 } from '@shein-aidc/icon-vue3'

export default {
  components: {
    sui_icon_doubt_14px_1,
    sui_icon_more_up_14px_1, 
    sui_icon_more_down_14px_1,
  },
  props: {
    taxes: {
      type: Array,
      default: () => [],
    },
    language: {
      type: Object,
      default: () => ({})
    },
    isMultiTax: {
      type: Boolean,
      default: false
    },
    isShowCaGst: {
      type: Boolean,
      default: false
    },
    partOrderInfo: {
      type: Object,
      default: () => {}
    },
    taxesMYInfo: {
      /**
       * totalTax 实付（Taxes Included）
       * companyTax 公司抵扣（Sales Tax absorbed by SHEIN）
       * personTax 个人支付（Sales Tax)
       */
      type: Object,
      default: () => {}
    },
    identification: { // 标识，参与按照数值进行订单明细排序用到
      type: String,
      default: 'one order multi tax'
    },
  },
  setup (props) {
    const multiTips = ref('')
    const showTaxInfoToggle = ref(false)
    const taxesInfo = ref([])
    const baseTaxesConfig = getTaxesConfig()

    const taxsWrapTitle = computed(() => {
      return appendColon(isCLCountryCode.value ? props.language.SHEIN_KEY_PC_31510 : props.language.SHEIN_KEY_PC_21334)
    })
    const newTaxes = computed(() => {
      // MY 站点公司税剔除ST
      return props.taxes.filter(f => !(f.taxPayType == 1 && f.taxType == 2))
    })
    const countryCodeValue = computed(() => {
      return props.partOrderInfo?.countryCode || ''
    })
    const isMYCountryCode = computed(() => {
      return ['MY']?.includes(countryCodeValue.value)
    })
    const isCLCountryCode = computed(() => {
      return ['CL']?.includes(countryCodeValue.value)
    })
    const isMultiCountryCode = computed(() => {
      // MY 站点下拉样式和一单多税一样
      return ['BR', 'MY', 'CL']?.includes(countryCodeValue.value)
    })
    const rewriteConfig = computed(() => {
      if (countryCodeValue.value === 'CL') {
        return {
          7: {
            langKey: 'SHEIN_KEY_PC_31315',
            hintKey: 'SHEIN_KEY_PC_31318',
          },
          3: {
            langKey: 'SHEIN_KEY_PC_31316',
            hintKey: 'SHEIN_KEY_PC_31319',
          },
          9: {
            langKey: 'SHEIN_KEY_PC_31317',
            hintKey: 'SHEIN_KEY_PC_31320',
            showIcon: true,
            taxType: 'company_tax'
          }
        }
      }
      if (isMultiCountryCode.value) return {
        3: {
          _name: 'ICMS',
          langKey: 'SHEIN_KEY_PC_26613',
          hintKey: 'SHEIN_KEY_PC_26614'
        },
        7: {
          _name: 'II',
          langKey: 'SHEIN_KEY_PC_26611',
          hintKey: 'SHEIN_KEY_PC_27362',
          showIcon: true
        },
        9: {
          _name: 'IVAT',
          langKey: 'SHEIN_KEY_PC_27345',
          hintKey: 'SHEIN_KEY_PC_27346',
          showIcon: true
        }
      }
      if (countryCodeValue.value === 'SG') {
        return {
          1: {
            ...baseTaxesConfig?.[1] || {},
            hintKey: 'SHEIN_KEY_PC_28888',
          }
        }
      }
      return {}
    })
    const personAllTax = computed(() => {
      return isMYCountryCode.value ? props.taxesMYInfo?.totalTax || {} : props.partOrderInfo?.person_all_tax || {}
    })

    const taxesMYBuild = () => {
      const { companyTax, personTax } = props.taxesMYInfo
      const titleText = (value) => value?.includes(':') ? value : `${value}:`
      const taxesInfo = [
        {
          showIcon: true,
          name: 'person_tax',
          title: titleText(props.language.SHEIN_KEY_PC_16414),
          hint: props.language.SHEIN_KEY_PC_28890,
          taxPrice: personTax,
          taxType: 'person_tax'
        },
        {
          showIcon: true,
          name: 'company_tax',
          title: titleText(props.language.SHEIN_KEY_PC_28892),
          hint: props.language.SHEIN_KEY_PC_28893,
          taxPrice: companyTax,
          taxType: 'company_tax'
        }]
      return taxesInfo.filter(f => f.taxPrice?.amount && Number(f.taxPrice?.amount) > 0)
    }

    const taxesCLBuild = (tax) => {
      if (tax?.taxType == 9) {
        return {
          ...tax,
          taxType: 'company_tax'
        }
      }
      if (tax?.taxType == 3) {
        return {
          ...tax,
          taxPrice: props.partOrderInfo?.total_vat_tax || {}
        }
      }
      return tax
    }

    const changeDutyAndVat = (taxArr) => {
      const dutyIndex = taxArr.findIndex(e => e.taxType == 7 && e.taxPayType == 2)
      if(dutyIndex === -1) return taxArr
      const vatIndex = taxArr.findIndex(e => e.taxType == 3 && e.taxPayType == 2)
      if(vatIndex === -1) return taxArr

      if (vatIndex > dutyIndex) return taxArr

      let temp = taxArr[dutyIndex]
      taxArr[dutyIndex] = taxArr[vatIndex]
      taxArr[vatIndex] = temp
      return taxArr
    }

    watch(() => [props.taxes, props.language], () => {
      const hits = []
      taxesInfo.value = []
      const multiTaxesConfig = getTaxesConfig({ rewriteConfig: rewriteConfig.value })

      const multiTaxes = isMultiCountryCode.value ? newTaxes.value : newTaxes.value?.filter(tax => ![8, 9]?.includes(tax?.taxType))
      // MY站点个人税
      if(isMYCountryCode.value) {
        props.taxesMYInfo && (taxesInfo.value = taxesMYBuild())
      } else {
        multiTaxes?.forEach(tax => {
          let { langKey, hintKey, showIcon } = multiTaxesConfig[tax?.taxType] || {}
          let titleText = props?.language[langKey] || ''
          titleText = titleText?.includes(':') ? titleText : `${titleText}:`
          taxesInfo.value.push({
            ...(isCLCountryCode.value ? taxesCLBuild(tax) : tax),
            title: titleText || '',
            hint: props?.language[hintKey] || '',
            showIcon: isMultiCountryCode.value ? showIcon : false
          })
          hits.push(hintKey ? props?.language[hintKey] : '')
        })
        // cl 调整关税和vat税位置
        isCLCountryCode.value && (taxesInfo.value = changeDutyAndVat(taxesInfo.value))
      }
      multiTips.value = isMYCountryCode.value ? props.language.SHEIN_KEY_PC_28889 : 
        isCLCountryCode.value ? props.language.SHEIN_KEY_PC_31509 : hits.filter(tip => tip).join('<br/>') || ''

    }, { immediate: true })

    return {
      taxesInfo,
      multiTips,
      isMultiCountryCode,
      personAllTax,
      showTaxInfoToggle,
      countryCodeValue,
      isMYCountryCode,
      isCLCountryCode,
      newTaxes,
      taxsWrapTitle,
      appendColon
    }
  }
}
</script>

<style lang="less" scoped>
.order-multi-taxes {
  margin-bottom: 8px;
  box-sizing: content-box;
  &__total-taxes {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    line-height: 17/14;
  }
  &__taxes-text {
    display: flex;
    align-items: center;
  }
  .summary-icon-doubt {
    color: @sui_color_gray_light1;
    margin-left: 2px;
    line-height: 1;
  }
}
.multi-item-wrapper {
  padding: 4px 6px;
  margin-top: 5px;
  background: #F4F4F4;
  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
    line-height: 14/12;
    font-size: 12px;
    color: var(---sui_color_gray_dark3, #767676);
    &:first-child {
      margin-top: 0;
    }
  }
  &__item-left {
    display: flex;
    align-items: center;
  }
  &__item-right {
    color: var(---sui_color_gray_dark3, #767676);
  }
  .tax-active{
    color: var(---sui_color_discount, #FA6338);
  }
}
</style>
